import { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { gcepPagesData, genericPagesData, hsepPagesData, ucepPagesData } from '../data/pages';
import { LinkProps } from '../design_system/src/web/atoms/Link';
import { ProductDataMappingType } from '../types';

export const productDataMapping: ProductDataMappingType = {
  'gcep': gcepPagesData,
  'ucep': ucepPagesData,
  'mate-act': hsepPagesData,
};

export type PageProps = {
  bannerImg?: string;
  gradType?: string;
  email?: string;
  hidePrimarySubLogo: boolean;
  links: LinkProps[];
  metaDescription: string;
  metaTitle: string;
  metaUrl: string;
  pageType: string;
  phoneNumber?: string;
  productCode?: string;
  programTitle?: string;
  protected: boolean;
  routes: string[];
  signInLink?: string;
  newInstitutionLink?: string;
  errorMessages?: Record<string, unknown>;
  subLogo: string;
  subLogoHeight: number;
  subLogoWidth: number;
};

export const PageContext = createContext<PageProps[] | undefined>([]);

export const PageProvider = ({ children }: any) => {
  const [pages, setPages] = useState<PageProps[] | undefined>();
  const location = useLocation();

  useEffect(() => {
    // Get the product from the pathname, after stripping any versioning
    const path = location.pathname.replace(/\/v\d+\//, '/');
    const productName = path.split('/')[1].toLowerCase();
    const productData = productDataMapping[productName];

    if (productData) {
      setPages(productData)
    } else {
      setPages(genericPagesData)
    }
  }, [location]);

  return <PageContext.Provider value={pages}>{children}</PageContext.Provider>;
};
