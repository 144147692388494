import { HeadProvider, Link, Meta, Title } from 'react-head';
import { EDHUB_TITLE } from '../../constants';

type MetaDataProps = { description: string; title: string; url: string };

const MetaData: React.FC<MetaDataProps> = ({ description, title, url }) => {
  return (
    <HeadProvider>
      {title === EDHUB_TITLE && <Meta name="robots" content="noindex,nofollow" />}
      <Meta name="description" content={description} />
      <Meta property="og:title" content={title} />
      <Meta property="og:url" content={url} />
      <Meta name="og:description" content={description} />
      <Link rel="canonical" href={url} />
      <Title>{title}</Title>
    </HeadProvider>
  );
};

export default MetaData;
