import { createContext, useEffect, useState } from 'react';
import productsData from '../data/products';

export type ProductProps = {
  audience: string;
  bannerImg: string;
  email: string;
  name: string;
  phoneNumber: string;
  product: string;
  signInLink: string;
};

export const ProductContext = createContext<ProductProps[] | undefined>([]);

export const ProductProvider = ({ children }: any) => {
  const [products, setProducts] = useState<ProductProps[]>();

  useEffect(() => {
    if (productsData) {
      setProducts(productsData);
    }
  }, []);

  return <ProductContext.Provider value={products}>{children}</ProductContext.Provider>;
};
