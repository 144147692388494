import { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { ASSET_URL } from '../../constants';
import { Button } from '../../design_system/src/web/atoms';

export const Banner = styled.div<any>`
  background-image: ${({ bannerImg }) => `url('${ASSET_URL}/${bannerImg}')`};

  background-repeat: no-repeat;
  background-size: cover;
  padding: 48px 0px;
  @media (max-width: 768px) {
    background-position: bottom;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 24px 30px 32px;
  }
`;

export const BannerContent = styled.div`
  margin: 0 auto;
  width: 70%;
  & > * {
    margin: ${({ theme }) => theme.spacing.medium}px 0px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledButton = styled(Button)`
  text-decoration: none;
  text-align: center;
  margin-top: 8px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

type BannerProps = {
  bannerImg: string;
  children: ReactNode;
};

const BannerWrapper: React.FC<BannerProps> = ({ bannerImg, children }) => {
  return (
    <Banner bannerImg={bannerImg}>
      <BannerContent>{children}</BannerContent>
    </Banner>
  );
};

export default BannerWrapper;
