import React from 'react';
import styled from 'styled-components/macro';

export interface CardProps {
  children: any;
  className?: string;
}

export const StyledCard = styled.div<CardProps>`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightBlue};
  border-radius: ${({ theme }) => theme.borders.radius.tiny}px;
  overflow: hidden;
`;

export const Card = ({ className, children }: CardProps): JSX.Element => {
  return <StyledCard className={className}>{children}</StyledCard>;
};

export default Card;
