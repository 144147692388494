import React from 'react';
import { ReactComponent as SvgIconCloseRounded } from '../../icons/SVG/ICON_CLOSE_ROUNDED.svg';
import SvgIcon from './SvgIcon';

export const IconCloseRounded = ({ className }: { className?: string }): JSX.Element => (
  <SvgIcon className={className}>
    <SvgIconCloseRounded />
  </SvgIcon>
);

export default IconCloseRounded;
