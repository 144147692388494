import { PageProps } from '../context/PageProvider';
import { Text } from '../design_system/src/web/atoms';
import AccessBanner from './AccessBanner';
import BannerWrapper from './shared/BannerWrapper';
import HelpWrapper from './shared/HelpWrapper';

type AccessPageProps = {
  page: PageProps;
};

const AccessPage: React.FC<AccessPageProps> = ({ page }) => {
  const { bannerImg, phoneNumber, email, signInLink, gradType, productCode }: PageProps = page;
  if (!bannerImg || !phoneNumber || !email || !signInLink || !gradType || !productCode) return null;
  return (
    <>
      <BannerWrapper bannerImg={bannerImg}>
        <AccessBanner signInLink={signInLink} gradType={gradType} productCode={productCode} />
      </BannerWrapper>
      <HelpWrapper>
        <Text tag="p" textStyle="standardContent" color="navy">
          Give us a call at {phoneNumber} or email <a href={`mailto:${email}`}>{email}</a>.
        </Text>
      </HelpWrapper>
    </>
  );
};

export default AccessPage;
