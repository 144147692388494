import { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { HELP } from '../../constants';
import { Text } from '../../design_system/src/web/atoms';

export const Wrapper = styled.div`
  width: 70%;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing.jumbo}px 0;
  & > * {
    margin: ${({ theme }) => theme.spacing.small}px 0px;
  }
  @media (max-width: 768px) {
    width: 90%;
    padding ${({ theme }) => theme.spacing.large}px 0px ${({ theme }) => theme.spacing.xlarge}px;
  }
`;
const HelpWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Wrapper>
      <Text tag="h1" textStyle="largeHeader" color="primaryPurple">
        {HELP}?
      </Text>
      {children}
    </Wrapper>
  );
};

export default HelpWrapper;
