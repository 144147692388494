import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Colors } from '../../tokens/Colors';
import IconArrowBothRounded from '../icons/IconArrowBothRounded';
import IconArrowDownRounded from '../icons/IconArrowDownRounded';
import IconArrowUpRounded from '../icons/IconArrowUpRounded';

type ColorKey = keyof typeof Colors;
type TableVariant = 'default' | 'condensed';

export interface TableContainerProps {
  children: any;
  className?: string;
}

export const StyledTableContainer = styled.div<TableContainerProps>`
  display: block;
  width: 100%;
  overflow-x: auto;
  max-width: 100%;
`;

export const TableContainer = ({ className, children }: TableContainerProps): JSX.Element => {
  return <StyledTableContainer className={className}>{children}</StyledTableContainer>;
};

export interface TableProps {
  children: any;
  className?: string;
  variant?: TableVariant;
}

export const StyledTable = styled.table<TableProps>`
  width: 100%;
  max-width: 100%;
  display: table;

  ${({ theme, variant }) =>
    variant === 'condensed'
      ? css`
          border-spacing: 0;
          border-collapse: collapse;
        `
      : css`
          border-spacing: 0 ${theme.spacing.tiny}px;
          border-collapse: separate;
        `}
`;

export const Table = ({ className, children, variant = 'default' }: TableProps): JSX.Element => {
  return (
    <StyledTable variant={variant} className={className}>
      {children}
    </StyledTable>
  );
};

export interface TableHeadProps {
  children: any;
  className?: string;
  variant?: TableVariant;
}

export const StyledTableHead = styled.thead<TableHeadProps>`
  display: table-header-group;
  vertical-align: middle;

  ${({ theme, variant = 'default' }) =>
    variant === 'condensed'
      ? css`
          border-bottom: 1px solid ${theme.colors.lightBlue};
        `
      : ''};
`;

export const TableHead = ({ className, children, variant = 'default' }: TableHeadProps): JSX.Element => {
  return (
    <StyledTableHead className={className} variant={variant}>
      {children}
    </StyledTableHead>
  );
};

export interface TableBodyProps {
  children: any;
  className?: string;
}

export const StyledTableBody = styled.tbody<TableBodyProps>`
  display: table-row-group;
  vertical-align: middle;
`;

export const TableBody = ({ className, children }: TableBodyProps): JSX.Element => {
  return <StyledTableBody className={className}>{children}</StyledTableBody>;
};

export interface TableRowProps {
  children: any;
  className?: string;
  onClick?: React.MouseEventHandler;
  disabled?: boolean;
  selectable?: boolean;
  variant?: TableVariant;
}

export const StyledTableRow = styled.tr<TableRowProps>`
  display: table-row;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme, variant = 'default', onClick, disabled = false, selectable = false }) =>
    variant === 'condensed'
      ? css`
          &:nth-child(odd) {
            background-color: ${theme.colors.almostWhite};
          }

          ${StyledTableHead} > & {
            background-color: ${({ theme }) => theme.colors.white};
          }

          ${(onClick || selectable) && !disabled
            ? css`
                &:hover {
                  background-color: ${theme.colors.lightBlue};
                  cursor: pointer;
                }
              `
            : ''}
        `
      : css`
          ${StyledTableHead} > & {
            background-color: transparent;
          }
        `}
`;

export const TableRow = ({
  className,
  children,
  onClick,
  variant = 'default',
  disabled = false,
  selectable = false,
}: TableRowProps): JSX.Element => {
  return (
    <StyledTableRow className={className} onClick={onClick} variant={variant} disabled={disabled} selectable={selectable}>
      {children}
    </StyledTableRow>
  );
};

export interface TableHeaderCellProps {
  children?: any;
  className?: string;
  sortDirection?: keyof typeof tableHeaderSortDirectionStyles;
  horizontalPadding?: keyof typeof tableHeaderHorizontalPaddingStyles;
  scope?: 'col' | 'row' | 'colgroup' | 'rowgroup';
  onClick?: React.MouseEventHandler;
  color?: ColorKey;
  variant?: TableVariant;
  colSpan?: number;
}

export const tableHeaderSortDirectionStyles = {
  default: css``,
  unsorted: css``,
  ascending: css``,
  descending: css``,
};

export const tableHeaderHorizontalPaddingStyles = {
  default: css`
    padding-left: ${({ theme }) => theme.spacing.medium}px;
    padding-right: ${({ theme }) => theme.spacing.medium}px;
  `,
  checkbox: css`
    padding-left: ${({ theme }) => theme.spacing.small}px;
    padding-right: ${({ theme }) => theme.spacing.small}px;
  `,
  none: css`
    padding-left: 0;
    padding-right: 0;
  `,
};

export const StyledTableHeaderCell = styled.th<TableHeaderCellProps>`
  font-family: ${({ theme }) => theme.fonts.sansBold};
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: ${({ theme, color }) => theme.colors[color || 'darkBlack']};
  text-align: left;
  vertical-align: middle;
  padding-top: ${({ theme }) => theme.spacing.small}px;
  padding-bottom: ${({ theme }) => theme.spacing.small}px;
  display: table-cell;

  ${({ sortDirection = 'default' }) => {
    return tableHeaderSortDirectionStyles[sortDirection as keyof typeof tableHeaderSortDirectionStyles];
  }}
  ${({ horizontalPadding = 'default' }) => {
    return tableHeaderHorizontalPaddingStyles[horizontalPadding as keyof typeof tableHeaderHorizontalPaddingStyles];
  }}
`;

export const StyledTableHeaderWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const StyledTableHeaderText = styled.span`
  display: inline-block;
`;

export const StyledTableHeaderIcon = styled.span`
  display: inline-block;
  height: ${({ theme }) => theme.spacing.large}px;
`;

export const TableHeaderCell = ({
  className,
  children,
  sortDirection,
  horizontalPadding = 'default',
  scope = 'col',
  onClick,
  variant = 'default',
  colSpan,
}: TableHeaderCellProps): JSX.Element => {
  return (
    <StyledTableHeaderCell
      sortDirection={sortDirection}
      horizontalPadding={horizontalPadding}
      scope={scope}
      className={className}
      onClick={onClick}
      variant={variant}
      colSpan={colSpan}
    >
      <StyledTableHeaderWrapper>
        <StyledTableHeaderText>{children}</StyledTableHeaderText>
        {sortDirection === 'descending' && (
          <StyledTableHeaderIcon>
            <IconArrowDownRounded />
          </StyledTableHeaderIcon>
        )}
        {sortDirection === 'ascending' && (
          <StyledTableHeaderIcon>
            <IconArrowUpRounded />
          </StyledTableHeaderIcon>
        )}
        {sortDirection === 'unsorted' && (
          <StyledTableHeaderIcon>
            <IconArrowBothRounded />
          </StyledTableHeaderIcon>
        )}
      </StyledTableHeaderWrapper>
    </StyledTableHeaderCell>
  );
};

export interface TableCellProps {
  children: any;
  className?: string;
  horizontalPadding?: keyof typeof tableCellHorizontalPaddingStyles;
  color?: ColorKey;
  variant?: TableVariant;
  colSpan?: number;
}

export const tableCellHorizontalPaddingStyles = {
  default: css`
    padding-left: ${({ theme }) => theme.spacing.medium}px;
    padding-right: ${({ theme }) => theme.spacing.medium}px;
  `,
  checkbox: css`
    padding-left: ${({ theme }) => theme.spacing.small}px;
    padding-right: ${({ theme }) => theme.spacing.small}px;
  `,
  none: css`
    padding-left: 0;
    padding-right: 0;
  `,
};

export const StyledTableCell = styled.td<TableCellProps>`
  color: ${({ theme, color = 'darkBlack' }) => theme.colors[color]};
  text-align: left;
  vertical-align: middle;
  padding-top: ${({ theme }) => theme.spacing.xlarge}px;
  padding-bottom: ${({ theme }) => theme.spacing.xlarge}px;
  word-wrap: break-word;
  display: table-cell;

  ${({ horizontalPadding = 'default' }) => {
    return tableCellHorizontalPaddingStyles[horizontalPadding as keyof typeof tableCellHorizontalPaddingStyles];
  }}
  ${({ theme, variant = 'default' }) =>
    variant === 'condensed'
      ? css`
          padding-top: ${theme.spacing.small}px;
          padding-bottom: ${theme.spacing.small}px;
        `
      : ''}
`;

export const TableCell = ({
  className,
  children,
  colSpan,
  horizontalPadding = 'default',
  variant = 'default',
}: TableCellProps): JSX.Element => {
  return (
    <StyledTableCell horizontalPadding={horizontalPadding} colSpan={colSpan} className={className} variant={variant}>
      {children}
    </StyledTableCell>
  );
};
