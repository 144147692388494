import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import Link, { LinkProps } from '../atoms/Link';
import IconCloseRounded from '../icons/IconCloseRounded';
import IconHamburger from '../icons/IconHamburger';
export interface HamburgerMenuProps {
  className?: string;
  links: LinkProps[];
}

type MenuProps = {
  isOpen?: boolean;
};

const StyledHamburgerMenu = styled.div`
  z-index: 10;
`;

const StyledMenu = styled.nav<MenuProps>`
  background-color: ${({ theme }) => theme.colors.darkBlack};
  height: 100vh;
  margin-top: 18px;
  opacity: 0.96;
  transform: translateX(-332px);
  transition: all 0.25s ease-in-out;
  width: 300px;
  z-index: 16;
  position: relative;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateX(-16px);
    `};

  @media (max-width: 768px) {
    margin-top: 8px;
  }
`;

const StyledMenuLinks = styled(Link)`
  border-left: solid 8px ${({ theme }) => theme.colors.primaryPurple};
  color: ${({ theme }) => theme.colors.white};
  display: block;
  padding: 7px 20px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const iconStyle = css`
  color: ${({ theme }) => theme.colors.greyBlue};
  cursor: pointer;
  width: 34px;

  @media (max-width: 768px) {
    width: 24px;
  }
`;

const StyledIconClose = styled(IconCloseRounded)`
  ${iconStyle}
`;

const StyledIconHamburger = styled(IconHamburger)`
  ${iconStyle}
`;

export const HamburgerMenu = ({ className, links }: HamburgerMenuProps): JSX.Element => {
  const [open, setOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    /***
    - Check to make sure clicked element is not a child of the hamburger menu
    - Icons are not evaluated as child elements as they are removed from the
      DOM on click. Confirm the clicked element is an icon by checking the nodeName
    ***/
    if (
      ref.current &&
      !ref.current.contains(event?.target as Node) &&
      event.target instanceof HTMLElement &&
      event.target.nodeName !== 'svg' &&
      event.target.nodeName !== 'path'
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <StyledHamburgerMenu className={className} ref={ref}>
      <div onClick={() => setOpen((prev) => !prev)}>{open ? <StyledIconClose /> : <StyledIconHamburger />}</div>
      <StyledMenu isOpen={open}>
        {links.map(({ className, href, title, target, rel, children }) => (
          <StyledMenuLinks className={className} key={title} href={href} rel={rel} target={target} title={title}>
            {children}
          </StyledMenuLinks>
        ))}
      </StyledMenu>
    </StyledHamburgerMenu>
  );
};

export default HamburgerMenu;
