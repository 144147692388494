import React from 'react';
import { ReactComponent as SvgIconArrowUp } from '../../icons/SVG/ICON_ARROW_UP.svg';
import SvgIcon from './SvgIcon';

export const IconArrowUp = ({ className }: { className?: string }): JSX.Element => (
  <SvgIcon className={className}>
    <SvgIconArrowUp />
  </SvgIcon>
);

export default IconArrowUp;
