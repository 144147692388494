import React, { ChangeEventHandler } from 'react';
import styled, { css } from 'styled-components/macro';
import Text from '../atoms/Text';

export interface RadioProps {
  id: string;
  name: string;
  value: any;
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  label?: string;
  disabled?: boolean;
  className?: string;
}

const Radio: React.FunctionComponent<RadioProps> = ({
  id,
  name,
  value,
  checked,
  onChange,
  label = '',
  disabled = false,
  className = '',
}) => {
  return (
    <RadioContainer className={className}>
      <StyleRadioLabel htmlFor={id} aria-checked={checked} tabIndex={checked ? 0 : -1}>
        <StyledRadio checked={checked} disabled={disabled}>
          <input type="radio" id={id} name={name} value={value} checked={checked} onChange={disabled ? undefined : onChange} />
          {checked && <FilledRadio />}
        </StyledRadio>
        {label && (
          <Text textStyle="standardContent" color={disabled ? 'greyBlue' : 'darkGrey'}>
            {label}
          </Text>
        )}
      </StyleRadioLabel>
    </RadioContainer>
  );
};

export const RadioContainer = styled.div``;

const StyleRadioLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.navy};
  outline: none;
`;

const radioDimension = 18;
const filledRadioDimention = 8;

const StyledRadio = styled.div<{ checked: boolean; disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${radioDimension}px;
  height: ${radioDimension}px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkBlack};
  padding: 0;
  margin-right: 10px;
  border: 1px solid ${({ theme, checked }) => (checked ? theme.colors.navy : theme.colors.greyBlue)};
  border-radius: ${radioDimension}px;
  outline: none;

  & > input {
    display: none;
  }

  ${({ theme, disabled, checked }) =>
    disabled &&
    css`
      border-color: ${theme.colors.greyBlue};

      ${checked
        ? css`
            background-color: ${theme.colors.almostWhite};
          `
        : css`
            background-color: ${theme.colors.almostWhite};
          `}

      ${FilledRadio} {
        background-color: ${theme.colors.greyBlue};
      }
    `}
`;

const FilledRadio = styled.div`
  width: ${filledRadioDimention}px;
  height: ${filledRadioDimention}px;
  background-color: ${({ theme }) => theme.colors.navy};
  border-radius: 50%;
`;

export default Radio;
