export const ABOUT_AMA = 'About AMA Education';
export const ABOUT_AMA_LINK = 'https://edhub.ama-assn.org/pages/about-ama-education';
export const ACCESS_CURRICULA = 'Login to access your courses and curricula';
export const ACTIVATE_ACCOUNT = 'Activate Your Account';
export const ASSET_URL = 'https://ama-edhub-design-system.s3.amazonaws.com/assets/images';
export const CLICK_HERE = 'Click Here.';
export const DONT_KNOW_CODE = "Don't know your code?";
export const EDHUB_DESCRIPTION = 'Activate your institutional product subscription';
export const EDHUB_LOGO_LIGHT_BACKGROUND = 'edhub-lightBg';
export const EDHUB_TITLE = 'Edhub Subscription';
export const EMAIL_ADDRESS_LABEL = 'email address';
export const ENTER_CODE_LABEL = 'Access Code';
export const ENTER_CODE_PLACEHOLDER = 'Enter Code';
export const ENTER_SECURITY_CODE = 'Enter your security code below.';
export const HELP = 'Help';
export const HELP_LINK = '#help';
export const HELP_PHONE_NUMBER = '(800) 262-3211';
export const HOME = 'Home';
export const HOME_LINK = 'https://edhub.ama-assn.org/';
export const IDLE_TIMEOUT_MESSAGE = "You've been logged out due to inactivity.";
export const INVALID_CODE_ERROR = 'Whoops! We didn’t recognize that code. Please try again.';
export const NOT_ALPHANUMERIC_ERROR = 'Invalid code. Code can not contain numbers or special characters.';
export const INVALID_EMAIL_ERROR = 'Please enter a valid email address.';
export const LEARN_MORE = 'Learn More';
export const MEMBER_BENEFITS = 'Member Benefits';
export const MEMBER_BENEFITS_LINK = 'https://www.ama-assn.org/amaone/ama-member-benefits';
export const META_URL = 'https://signin.curriculum.ama-assn.org';
export const PENDING_SUBMIT_MODAL_TEXT = 'Thank you! This may take a minute...';
export const PENDING_SUCCESS_MODAL_TEXT = 'Thank you! This may take a minute...';
export const SCHEMA_TAG = { '@context': 'http://schema.org/', '@type': 'WebSite' };
export const SERVER_ERROR = 'SERVER';
export const SIGN_IN = 'Sign In';
export const SUBMIT = 'Submit';
export const SUCCESS_ACCESS_CODE = 'We sent a code to your email. Check your inbox to finish activating your account.';
export const TARGET_BLANK = '_blank';
export const TARGET_SELF = '_self';
export const WELCOME = 'Welcome!';

// GCEP Constants
export const GCEP_ACCESS_DESCRIPTION = 'Unlock your institutional subscription';
export const GCEP_BANNER = 'banner-default.png';
export const GCEP_DESCRIPTION =
  'Explore the AMA GME Competency Education Program’s online educational courses designed to complement teachings in residency and fellowship programs. GCEP’s robust library of coursework helps residents and their institutions meet common program requirements.';
export const GCEP_ED_TYPE = 'GME';
export const GCEP_HELP_EMAIL = 'GCEPHelp@ama-assn.org';
export const GCEP_PRODUCT_CODE = 'gcep';
export const GCEP_PROGRAM_NAME = 'AMA GME Competency Education Program';
export const GCEP_GENERIC_SERVER_ERROR =
  'Whoops! Something went wrong. Please try again. If you continue to receive this error, please contact us at (800) 262-3211 or email GCEPHelp@ama-assn.org so we can help you gain access.';
export const GCEP_SERVER_ERROR_MESSAGE =
  'Whoops! Something went wrong when we tried to activate your account. Please contact us at (800) 262-3211 or email GCEPhelp@ama-assn.org so we can help you log in.';
export const GCEP_UNRECOGNIZED_EMAIL_ERROR =
  'Whoops! We didn’t recognize that email address, please try again. If you need help, call (800) 262-3211 or email GCEPhelp@ama-assn.org.';
export const GCEP_LEARN_MORE_LINK = 'https://cloud.e.ama-assn.org/21-1518-GCEP';
export const GME_LOGO_LIGHT_BACKGROUND = 'gme-lightBg';

// UCEP Constants
export const UCEP_ACCESS_DESCRIPTION = 'Unlock your institutional subscription';
export const UCEP_BANNER = 'banner-ucep-small.png';
export const UCEP_DESCRIPTION =
  'The AMA UME Curricular Enrichment Program offers institutions a series of engaging, high-quality and interactive modules developed to easily fill emerging curricular gaps and support tracking of medical student progress.';
export const UCEP_ED_TYPE = 'UME';
export const UCEP_HELP_EMAIL = 'UMEHelp@ama-assn.org';
export const UCEP_PRODUCT_CODE = 'ucep';
export const UCEP_PROGRAM_NAME = 'AMA UME Curricular Enrichment Program';
export const UCEP_GENERIC_SERVER_ERROR =
  'Whoops! Something went wrong. Please try again. If you continue to receive this error, please contact us at (800) 262-3211 or email UMEHelp@ama-assn.org so we can help you gain access.';
export const UCEP_SERVER_ERROR_MESSAGE =
  'Whoops! Something went wrong when we tried to activate your account. Please contact us at (800) 262-3211 or email UMEHelp@ama-assn.org so we can help you log in.';
export const UCEP_UNRECOGNIZED_EMAIL_ERROR =
  'Whoops! We didn’t recognize that email address, please try again. If you need help, call (800) 262-3211 or email UMEHelp@ama-assn.org.';
export const UCEP_LEARN_MORE_LINK = 'https://ama-assn.org/ume-resource-program';
export const UME_LOGO_LIGHT_BACKGROUND = 'ucep-logo-lightBg';

// HSEP Constants
export const HSEP_ACCESS_DESCRIPTION = 'Unlock your subscription';
export const HSEP_BANNER = 'banner-hsep-dark.png';
export const HSEP_DESCRIPTION = 'AMA Opioids Substance Use Curriculum';
export const HSEP_ED_TYPE = 'mate-act';
export const HSEP_HELP_EMAIL = 'msc@ama-assn.org';
export const HSEP_PRODUCT_CODE = 'mate-act';
export const HSEP_PROGRAM_NAME = 'AMA Opioids Substance Use Curriculum';
export const HSEP_GENERIC_SERVER_ERROR =
  'Whoops! Something went wrong. Please try again. If you continue to receive this error, please contact us at (800) 262-3211 or email msc@ama-assn.org so we can help you gain access.';
export const HSEP_SERVER_ERROR_MESSAGE =
  'Whoops! Something went wrong when we tried to activate your account. Please contact us at (800) 262-3211 or email msc@ama-assn.org so we can help you log in.';
export const HSEP_UNRECOGNIZED_EMAIL_ERROR =
  'Whoops! We didn’t recognize that email address, please try again. If you need help, call (800) 262-3211 or email msc@ama-assn.org.';
export const HSEP_LEARN_MORE_LINK = '#';
export const HSEP_LOGO_LIGHT_BACKGROUND = 'hsep-logo-lightBg';
