import React from 'react';
import styled from 'styled-components';

const StyledSvgIcon = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;

  flex: 0 0 auto;
`;

export const SvgIcon = ({ children, className }: { children: React.ReactNode; className?: string }): JSX.Element => {
  return <StyledSvgIcon className={className}>{children}</StyledSvgIcon>;
};

export default SvgIcon;
