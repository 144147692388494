import { isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import axiosInstance from './curriculumClient';

interface Response {
  data?: any;
  status: number;
}

interface AxiosHook {
  response: Response | null;
  loading: boolean;
  axiosFetch: (url: string, method?: string, data?: any) => void;
}

const useAxios = (): AxiosHook => {
  const [response, setResponse] = useState<Response | null>(null);
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const axiosFetch = async (url: string, method = 'get', data = {}) => {
    setLoading(true);
    setResponse(null);
    const ctrl = new AbortController();

    try {
      const res = await axiosInstance.request({
        data,
        signal: ctrl.signal,
        method,
        url,
        withCredentials: true,
      });
      if (isMounted.current) {
        setResponse({ status: res.status, data: res.data });
      }
    } catch (error: any) {
      if (!isEmpty(error)) {
        if (isMounted.current) {
          setResponse({ status: error?.response?.status });
        }
      }
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  return { response, loading, axiosFetch };
};

export default useAxios;
