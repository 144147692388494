import styled from 'styled-components/macro';
import { ACCESS_CURRICULA, SIGN_IN, WELCOME } from '../constants';
import { Text } from '../design_system/src/web/atoms';
import { StyledButton } from './shared/BannerWrapper';

export const Title = styled.span`
  display: inline-block;
`;

const WelcomeBanner: React.FC<{ programTitle: string; signInLink: string }> = ({ programTitle, signInLink }) => {
  if (!programTitle) return null;
  return (
    <>
      <Text tag="p" textStyle="largeContent" color="white">
        <Title>{programTitle}</Title>
      </Text>
      <Text tag="h1" textStyle="largestHeader" color="white">
        {WELCOME}
      </Text>
      <Text tag="p" textStyle="largeContent" color="white">
        {ACCESS_CURRICULA}
      </Text>
      <StyledButton buttonStyle="secondary" href={signInLink} target="_self">
        {SIGN_IN}
      </StyledButton>
    </>
  );
};

export default WelcomeBanner;
