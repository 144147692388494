import { map } from 'lodash';
import React from 'react';
import styled from 'styled-components/macro';

export const Colors = {
  darkBlack: '#16202D',
  navy: '#243647',
  greyBlue: '#8DA3B5',
  lightGreyBlue: '#ECF2F7',
  lightGrey: '#EEF2F4',
  darkGrey: '#62707C',
  disabledGrey: '#c6d1da', // greyBlue with 50% opacity
  almostWhite: '#F4F6F7',
  primaryPurple: '#7770fc',
  amaGreen: '#81CB3F',
  lightBlue: '#D0DCE6',
  amaRed: '#FC5E47',
  amaYellow: '#F2BC35',
  white: '#ffffff',
};

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Cell = styled.div`
  flex: 0 0 25%;
`;
const ColorBlock = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${(props) => props.color};
`;

const ColorsDocumentation = (): JSX.Element => {
  return (
    <Grid>
      <h2>Color Colors</h2>
      <Grid>
        {map(Colors, (value: string, key: string) => {
          return (
            <Cell key={key}>
              <p>{key}</p>
              <ColorBlock color={value} />
            </Cell>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default ColorsDocumentation;
