import React from 'react';
import { ReactComponent as SvgIconArrowDown } from '../../icons/SVG/ICON_ARROW_DOWN.svg';
import SvgIcon from './SvgIcon';

export const IconArrowDown = ({ className }: { className?: string }): JSX.Element => (
  <SvgIcon className={className}>
    <SvgIconArrowDown />
  </SvgIcon>
);

export default IconArrowDown;
