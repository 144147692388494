import React, { useReducer } from 'react';
import { flashReducer, initialState } from '../reducers/flashReducer';

interface FlashState {
  flashState: typeof initialState;
  flashDispatch: React.Dispatch<any>;
}

export const FlashContext = React.createContext<FlashState>({} as FlashState);

interface FlashProviderProps {
  children: React.ReactNode;
}

export const FlashProvider: React.FC<FlashProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(flashReducer, initialState);

  return <FlashContext.Provider value={{ flashState: state, flashDispatch: dispatch }}>{children}</FlashContext.Provider>;
};
