import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Text } from '../design_system/src/web/atoms';

const StyledContainer = styled('div')`
  padding-top: 20px;
  * {
    margin: 15px 0px;
  }

  margin: 0 auto;
  width: 80%;
`;

const NotFound = () => {
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate('/');
  };

  return (
    <>
      <StyledContainer>
        <Text textStyle="largeHeader">404. Page Not Found.</Text>
        <Text tag="p">The page you're looking for cannot be found. Please check the address, or try visiting the home page.</Text>
        <Button onClick={navigateHome}>Go Back</Button>
      </StyledContainer>
    </>
  );
};

export default NotFound;
