import * as EmailValidator from 'email-validator';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { requestSubmissionCode } from '../api/requests';
import useAxios from '../api/useAxios';
import {
  CLICK_HERE,
  DONT_KNOW_CODE,
  PENDING_SUCCESS_MODAL_TEXT,
} from '../constants';
import { FlashContext } from '../context/FlashProvider';
import { Text } from '../design_system/src/web/atoms';
import { errorMessages } from '../mappings';
import AccessEmailForm from './AccessEmailForm';
import Modal from './shared/ModalWrapper';

const StyledLink = styled.a`
  color: #e5d3e8;
`;

const StyledAccessText = styled(Text)`
  margin: 0px !important;
`;

const StyledModalText = styled(Text)`
  text-align: center;
`;

const AccessResetCode: React.FC<{ gradType: string; productCode: string }> = ({ gradType, productCode }) => {
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const { flashDispatch } = useContext(FlashContext);
  const { response, loading, axiosFetch } = useAxios();

  useEffect(() => {
    if (response?.status === 202) {
      flashDispatch({ message: errorMessages[productCode].checkEmail, type: 'success' });
      setShowModal(false);
    } else if (response?.status === 500) {
      flashDispatch({ message: errorMessages[productCode].generic, type: 'error' });
      setShowModal(false);
    } else if (response?.status === 404) {
      setError(errorMessages[productCode].unrecognizedEmail);
    }
  }, [response?.status, flashDispatch, setError, setShowModal, productCode]);

  const handleSubmitEmail = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setSubmitted(true);
    const validEmail = EmailValidator.validate(email);
    if (!validEmail) {
      setError(errorMessages[productCode].invalidEmail);
    } else {
      await requestSubmissionCode({ email, productCode: productCode as string }, axiosFetch);
    }
    setSubmitted(false);
  };

  const resetForm = () => {
    setError(null);
    setEmail('');
    setSubmitted(false);
  };

  const handleCloseModal = () => {
    resetForm();
    setShowModal(false);
  };

  const handleOpenModal = () => {
    resetForm();
    flashDispatch({ type: 'reset' });
    setShowModal(true);
  };

  const showEmailForm = showModal && (!loading || response?.status === 404);

  return (
    <>
      <StyledAccessText tag="p" textStyle="standardContent" color="white">
        {DONT_KNOW_CODE} 
        <StyledLink href="#" onClick={handleOpenModal} title="Don't know your code? Click here.">
          {CLICK_HERE}
        </StyledLink>
      </StyledAccessText>

      {showEmailForm && (
        <Modal onClose={handleCloseModal} isActive={showEmailForm}>
          <AccessEmailForm
            gradType={gradType}
            email={email}
            error={error}
            handleSubmitEmail={handleSubmitEmail}
            setEmail={setEmail}
            submitted={submitted}
          />
        </Modal>
      )}

      {loading && (
        <Modal displayLoading={true} hideClose={true} isActive={loading}>
          <StyledModalText tag="p">{PENDING_SUCCESS_MODAL_TEXT}</StyledModalText>
        </Modal>
      )}
    </>
  );
};

export default AccessResetCode;
