import React from 'react';
import styled from 'styled-components/macro';

export interface InputProps {
  name: string;
  value?: string;
  hasError?: boolean;
  disabled?: boolean;
  placeholder?: string;
  id?: string;
  onChange?: React.ChangeEventHandler;
  onKeyPress?: React.KeyboardEventHandler;
  maxLength?: number;
}

const StyledInput = styled.input<{ onKeyPress?: React.KeyboardEventHandler }>`
  appearance: none;
  height: ${({ theme }) => theme.inputs.height};
  font-size: ${({ theme }) => theme.inputs.fontSize};
  font-family: ${({ theme }) => theme.fonts.sansRegular};
  width: 100%;
  border: 0;
  padding: 0 ${({ theme }) => theme.spacing.large}px 0 ${({ theme }) => theme.spacing.medium}px;
  border-radius: ${({ theme }) => theme.borders.radius.tiny}px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkBlack};
  border: 1px solid ${({ theme, hasError }: any) => (hasError ? theme.colors.amaRed : theme.colors.greyBlue)};

  &:disabled {
    background-color: ${({ theme }) => theme.colors.almostWhite};
    border: 1px solid ${({ theme }) => theme.colors.lightBlue};
    color: ${({ theme }) => theme.colors.greyBlue};
  }
`;

const Input = ({ name, ...rest }: InputProps) => {
  return <StyledInput name={name} {...rest} />;
};

export default Input;
