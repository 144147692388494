export const requestSubmissionCode = async (
  queryParams: { email: string; productCode: string },
  axiosFetch: (url: string, method?: string, data?: any) => void,
) => {
  await axiosFetch(`/request-code?emailAddress=${queryParams.email}&productCode=${queryParams.productCode}`);
};

export const submitSubmissionCode = async (
  queryParams: { subscriptionCode: string },
  axiosFetch: (url: string, method?: string, data?: any) => void,
) => {
  await axiosFetch(`/confirm-subscription?subscriptionCode=${queryParams.subscriptionCode}`);
};

export const getTokens = async (axiosFetch: (url: string, method?: string, data?: any) => void) => {
  await axiosFetch(`/token`);
};

export const getRefreshToken = async (queryParams: { refresh: string }, axiosFetch: (url: string, method?: string, data?: any) => void) => {
  await axiosFetch(`/refresh?refresh=${queryParams.refresh}`);
};
