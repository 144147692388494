import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CenteredNavigation from '../molecules/CenteredNavigation';
import { SignInProps } from '../molecules/SignIn';
import { User, UserMenuOption } from '../molecules/UserMenu';
import Header from './Header';
import { LinkProps } from './Link';
import SubHeader from './SubHeader';

const TARGET_POSITION = 38;
const NAV_HEIGHT = 132;

export type HeaderUserProps = {
  user?: User | Record<string, unknown>;
  userMenuLink: UserMenuOption;
};

type ScrollingHeaderProps = {
  subLogo: string;
  signIn: SignInProps;
  links: LinkProps[];
  subLogoWidth: number;
  subLogoHeight: number;
  userMenu: HeaderUserProps;
  hidePrimarySubLogo?: boolean;
  hideSignIn?: boolean;
};

type WrapperProps = {
  sticky?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  height: '100%';
  & + div {
    margin-top: ${({ sticky }) => sticky && '175px'};
  }
  @media (max-width: 768px) {
    & + div {
      margin-top: ${({ sticky }) => sticky && '98px'};
    }
  }
`;

const StyledCenteredNavigation = styled(CenteredNavigation)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const ScrollingHeader = ({
  links,
  signIn,
  subLogo,
  subLogoWidth,
  subLogoHeight,
  userMenu,
  hidePrimarySubLogo,
  hideSignIn,
}: ScrollingHeaderProps): JSX.Element => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isInitialState, setIsVisible] = useState(true);

  const listenToScroll = () => {
    const scrollPosition = window.scrollY;
    const contentHeight = document.body.getBoundingClientRect().height;
    const windowHeight = window.innerHeight;

    const shouldScroll = contentHeight > windowHeight + NAV_HEIGHT;

    if (scrollPosition > TARGET_POSITION && shouldScroll) {
      setIsVisible(false);
    } else if (scrollPosition === 0 && !isInitialState) {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  });

  return (
    <Wrapper ref={scrollRef} sticky={!isInitialState}>
      {isInitialState && <Header links={links} />}
      <SubHeader
        hidePrimarySubLogo={isInitialState ? hidePrimarySubLogo : false}
        hideSignIn={hideSignIn}
        links={isInitialState ? undefined : links}
        sticky={!isInitialState && true}
        signIn={signIn}
        subLogo={subLogo}
        subLogoHeight={subLogoHeight}
        subLogoWidth={subLogoWidth}
        userMenu={userMenu}
      />
      <StyledCenteredNavigation links={links} sticky={!isInitialState} />
    </Wrapper>
  );
};

export default ScrollingHeader;
