import React from 'react';
import { ReactComponent as SvgIconUser } from '../../icons/SVG/ICON_USER.svg';
import SvgIcon from './SvgIcon';

export const IconUser = ({ className }: { className?: string }): JSX.Element => (
  <SvgIcon className={className}>
    <SvgIconUser />
  </SvgIcon>
);

export default IconUser;
