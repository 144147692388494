import React from 'react';
import styled from 'styled-components/macro';

export interface LinkProps {
  className?: string;
  href: string;
  children?: any;
  title: string;
  target?: string;
  rel?: string;
}

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.primaryPurple};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Link = ({ className, href, children, title, target }: LinkProps): JSX.Element => {
  return (
    <StyledLink className={className} href={href} title={title} target={target}>
      {children}
    </StyledLink>
  );
};

export default Link;
