import {
  GCEP_GENERIC_SERVER_ERROR,
  GCEP_SERVER_ERROR_MESSAGE,
  GCEP_UNRECOGNIZED_EMAIL_ERROR,
  HSEP_GENERIC_SERVER_ERROR,
  HSEP_SERVER_ERROR_MESSAGE,
  HSEP_UNRECOGNIZED_EMAIL_ERROR,
  IDLE_TIMEOUT_MESSAGE,
  INVALID_CODE_ERROR,
  INVALID_EMAIL_ERROR,
  NOT_ALPHANUMERIC_ERROR,
  SUCCESS_ACCESS_CODE,
  UCEP_GENERIC_SERVER_ERROR,
  UCEP_SERVER_ERROR_MESSAGE,
  UCEP_UNRECOGNIZED_EMAIL_ERROR,
} from './constants';
import { ErrorMappingType } from './types';

export const errorMessages: ErrorMappingType = {
  'mate-act': {
    generic: HSEP_GENERIC_SERVER_ERROR,
    server: HSEP_SERVER_ERROR_MESSAGE,
    unrecognizedEmail: HSEP_UNRECOGNIZED_EMAIL_ERROR,
    inactivity: IDLE_TIMEOUT_MESSAGE,
    invalidCode: INVALID_CODE_ERROR,
    notAlphanumeric: NOT_ALPHANUMERIC_ERROR,
    checkEmail: SUCCESS_ACCESS_CODE,
    invalidEmail: INVALID_EMAIL_ERROR,
  },
  'gcep': {
    generic: GCEP_GENERIC_SERVER_ERROR,
    server: GCEP_SERVER_ERROR_MESSAGE,
    unrecognizedEmail: GCEP_UNRECOGNIZED_EMAIL_ERROR,
    inactivity: IDLE_TIMEOUT_MESSAGE,
    invalidCode: INVALID_CODE_ERROR,
    notAlphanumeric: NOT_ALPHANUMERIC_ERROR,
    checkEmail: SUCCESS_ACCESS_CODE,
    invalidEmail: INVALID_EMAIL_ERROR,
  },
  'ucep': {
    generic: UCEP_GENERIC_SERVER_ERROR,
    server: UCEP_SERVER_ERROR_MESSAGE,
    unrecognizedEmail: UCEP_UNRECOGNIZED_EMAIL_ERROR,
    inactivity: IDLE_TIMEOUT_MESSAGE,
    invalidCode: INVALID_CODE_ERROR,
    notAlphanumeric: NOT_ALPHANUMERIC_ERROR,
    checkEmail: SUCCESS_ACCESS_CODE,
    invalidEmail: INVALID_EMAIL_ERROR,
  }
};
