import React, { ButtonHTMLAttributes } from 'react';
import styled, { css, DefaultTheme, FlattenInterpolation, keyframes, ThemeProps } from 'styled-components/macro';
import IconSpinner from '../icons/IconSpinner';
import Text from './Text';

type ButtonStyle = 'primary' | 'secondary' | 'text' | 'icon';
export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonStyle?: ButtonStyle;
  onClick?: React.MouseEventHandler;
  loading?: boolean;
  href?: string;
  target?: string;
  children: any;
  className?: string;
  component?: React.ReactElement<any>;
  testId?: string;
}

const StyledIconSpinner = styled(IconSpinner)<{ buttonStyle?: ButtonStyle }>`
  color: ${({ theme, buttonStyle }) => (buttonStyle === 'primary' ? theme.colors.white : theme.colors.darkBlack)};
  height: 16px;
  width: 16px;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledSpinnerContainer = styled.div`
  display: inline-block;
  animation: ${spin} 0.8s infinite linear;
  width: 16px;
  height: 16px;
`;

const Spinner = ({ buttonStyle }: { buttonStyle?: ButtonStyle }) => {
  return (
    <StyledSpinnerContainer>
      <StyledIconSpinner buttonStyle={buttonStyle} />
    </StyledSpinnerContainer>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const buttonStyles: { [key in ButtonStyle]: FlattenInterpolation<ThemeProps<DefaultTheme>> } = {
  primary: css`
    background-color: ${({ theme }) => theme.colors.primaryPurple};
    border: ${({ theme }) => theme.borders.width?.thin}px solid ${({ theme }) => theme.colors.primaryPurple};

    padding: ${({ theme }) => theme.spacing.small}px ${({ theme }) => theme.spacing.large}px;
    border-radius: ${({ theme }) => theme.borders.radius?.tiny}px;
    height: 44px;

    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.colors.darkBlack};
      background-color: ${({ theme }) => theme.colors.darkBlack};
      box-shadow: ${({ theme }) => theme.shadows.default};
      cursor: pointer;
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.disabledGrey};
      border-color: ${({ theme }) => theme.colors.disabledGrey};
      cursor: not-allowed;

      &:hover {
        background-color: ${({ theme }) => theme.colors.disabledGrey};
        box-shadow: none;
      }
    }
  `,
  secondary: css`
    background-color: ${({ theme }) => theme.colors.white};
    border: ${({ theme }) => theme.borders.width?.thin}px solid transparent;

    padding: ${({ theme }) => theme.spacing.small}px ${({ theme }) => theme.spacing.large}px;
    border-radius: ${({ theme }) => theme.borders.radius?.small}px;
    height: 44px;

    &:hover,
    &:focus {
      border-color: transparent;
      background-color: ${({ theme }) => theme.colors.white};
      box-shadow: ${({ theme }) => theme.shadows.default};
      cursor: pointer;
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.almostWhite};
      border-color: ${({ theme }) => theme.colors.almostWhite};
      cursor: not-allowed;

      &:hover {
        background-color: ${({ theme }) => theme.colors.almostWhite};
        box-shadow: none;
      }
    }
  `,
  text: css`
    border: none;
    background-color: transparent;
    padding: ${({ theme }) => theme.spacing.small}px ${({ theme }) => theme.spacing.large}px;
    height: 44px;

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.primaryPurple};
      cursor: pointer;
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.greyBlue};
      cursor: not-allowed;

      &:hover {
        background-color: ${({ theme }) => theme.colors.almostWhite};
        box-shadow: none;
      }
    }
  `,
  icon: css`
    border: none;
    background-color: transparent;

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.primaryPurple};
      cursor: pointer;
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.greyBlue};
      cursor: not-allowed;

      &:hover {
        background-color: ${({ theme }) => theme.colors.almostWhite};
        box-shadow: none;
      }
    }
  `,
};

export const StyledLink = styled.a<ButtonProps>`
  ${({ buttonStyle = 'primary' }) => buttonStyles[buttonStyle]}
  display: inline-block;
`;

export const StyledButton = styled.button<ButtonProps>`
  ${({ buttonStyle = 'primary' }) => buttonStyles[buttonStyle]}
  display: flex;
  align-items: center;
  justify-content: center;
`;

// React.cloneElement here is what allows us to pass in any ReactElement,
// such as react-router-dom's Link component, via Button's `component` prop
const StyledButtonComponent = styled(({ component, buttonStyle, className, children }: ButtonProps) =>
  component ? React.cloneElement(component, { $buttonStyle: buttonStyle, className }, children) : null
)<ButtonProps>`
  ${({ buttonStyle = 'primary' }) => buttonStyles[buttonStyle]};
  display: inline-block;
`;

export const Button = ({
  className,
  buttonStyle = 'primary',
  href,
  target,
  onClick,
  children,
  component,
  testId,
  loading = false,
  disabled = false,
  type = 'button',
}: ButtonProps): JSX.Element => {
  const inner =
    typeof children === 'string' ? (
      <Text color={buttonStyle === 'primary' ? 'white' : 'darkBlack'} textStyle="buttonText">
        {children}
      </Text>
    ) : (
      children
    );

  if (component) {
    return (
      <StyledButtonComponent
        component={component}
        href={href}
        target={target}
        role="button"
        onClick={onClick}
        className={className}
        buttonStyle={buttonStyle}
        disabled={loading || disabled}
      >
        {inner}
      </StyledButtonComponent>
    );
  }

  if (href) {
    return (
      <StyledLink
        href={href}
        target={target}
        role="button"
        onClick={onClick}
        className={className}
        buttonStyle={buttonStyle}
        disabled={loading || disabled}
      >
        {inner}
      </StyledLink>
    );
  }

  return (
    <StyledButton
      href={href}
      onClick={onClick}
      className={className}
      buttonStyle={buttonStyle}
      disabled={loading || disabled}
      type={type}
      data-testid={testId}
    >
      {loading ? <Spinner buttonStyle={buttonStyle} /> : inner}
    </StyledButton>
  );
};

export default Button;
