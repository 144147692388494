import {
  GCEP_BANNER,
  GCEP_HELP_EMAIL,
  GCEP_PRODUCT_CODE,
  GCEP_PROGRAM_NAME,
  HELP_PHONE_NUMBER,
  HSEP_BANNER,
  HSEP_HELP_EMAIL,
  HSEP_PRODUCT_CODE,
  HSEP_PROGRAM_NAME,
  UCEP_BANNER,
  UCEP_HELP_EMAIL,
  UCEP_PRODUCT_CODE,
  UCEP_PROGRAM_NAME
} from '../constants';
import { signInLink } from '../links';

export default [
  {
    audience: 'Residents',  // Currently not in use in the UI
    bannerImg: GCEP_BANNER,
    email: GCEP_HELP_EMAIL,
    name: GCEP_PROGRAM_NAME,
    phoneNumber: HELP_PHONE_NUMBER,
    product: GCEP_PRODUCT_CODE,
    signInLink: `${signInLink}?productCode=${GCEP_PRODUCT_CODE}`,
  },
  {
    audience: 'Medical Students', // Currently not in use in the UI
    bannerImg: UCEP_BANNER,
    email: UCEP_HELP_EMAIL,
    name: UCEP_PROGRAM_NAME,
    phoneNumber: HELP_PHONE_NUMBER,
    product: UCEP_PRODUCT_CODE,
    signInLink: `${signInLink}?productCode=${UCEP_PRODUCT_CODE}`,
  },
  {
    audience: 'Physicians', // Currently not in use in the UI
    bannerImg: HSEP_BANNER,
    email: HSEP_HELP_EMAIL,
    name: HSEP_PROGRAM_NAME,
    phoneNumber: HELP_PHONE_NUMBER,
    product: HSEP_PRODUCT_CODE,
    signInLink: `${signInLink}?productCode=${HSEP_PRODUCT_CODE}`,
  },
];
