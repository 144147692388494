import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components/macro';
import MetaData from '../components/shared/MetaData';
import SchemaScriptTag from '../components/shared/SchemaScriptTag';
import { AuthContext } from '../context/AuthProvider';
import { FlashContext } from '../context/FlashProvider';
import { PageProps } from '../context/PageProvider';
import { Alert, Footer } from '../design_system/src/web/atoms';
import ScrollingHeader from '../design_system/src/web/atoms/ScrollingHeader';

const StyledAlert = styled.div`
  margin-bottom: 0px;
`;

const StyledFooter = styled(Footer)`
  margin-top: auto;
`;

const Layout = ({ page }: { page: PageProps }): JSX.Element | null => {
  const { user, logoutUser } = useContext(AuthContext);
  const { flashState } = useContext(FlashContext);
  const { links, subLogo, subLogoHeight, subLogoWidth, hidePrimarySubLogo, metaDescription, metaTitle, metaUrl, signInLink } = page;

  return (
    <>
      <MetaData description={metaDescription} url={metaUrl} title={metaTitle} />
      <ScrollingHeader
        links={links}
        subLogo={subLogo}
        subLogoHeight={subLogoHeight}
        subLogoWidth={subLogoWidth}
        signIn={{
          link: {
            title: 'Sign In',
            children: 'Sign In',
            target: '_self',
            href: signInLink || '',
          },
        }}
        hidePrimarySubLogo={hidePrimarySubLogo}
        hideSignIn={page.pageType === 'root'}
        userMenu={{
          user,
          userMenuLink: {
            displayText: 'Sign Out',
            onClick: () => {
              return logoutUser();
            },
          },
        }}
      />

      {flashState.message !== '' && (
        <StyledAlert>
          <Alert $type={flashState.type as any} text={flashState.message} />
        </StyledAlert>
      )}

      <Outlet />
      <StyledFooter links={links} />
      <SchemaScriptTag url={metaUrl} title={metaTitle} />
    </>
  );
};

export default Layout;
