import jwtDecode from 'jwt-decode';
import { createContext, useEffect, useRef, useState } from 'react';
import { getRefreshToken, getTokens } from '../api/requests';
import useAxios from '../api/useAxios';
import useInactivityLogout from '../hooks/useInactivityTimeout';
import { signOutLink } from '../links';
export const AuthContext = createContext<any>({});

const NINE_MINUTES = 1000 * 60 * 9;
const IDLE_TIMEOUT = Number(process.env.REACT_APP_IDLE_TIMEOUT) || 1000 * 60 * 8 // 8 minutes
const THROTTLE_DURATION = Number(process.env.REACT_APP_THROTTLE_DURATION) || 1000 * 60 * 0.5 // 30 seconds

export const AuthProvider = ({ children }: any) => {
  const [authTokens, setAuthTokens] = useState(() =>
    sessionStorage.getItem('authTokens') ? JSON.parse(sessionStorage.getItem('authTokens') as string) : null,
  );
  const [user, setUser] = useState(() =>
    sessionStorage.getItem('authTokens') ? jwtDecode(sessionStorage.getItem('authTokens') as string) : null,
  );
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState<any>(true);
  const { response: tokenResponse, axiosFetch: tokenFetch } = useAxios();
  const { response: refreshResponse, axiosFetch: refreshFetch } = useAxios();

  const logoutUser = (queryParams?: { error?: string }) => {
    const signOutRedirect = queryParams?.error ? `${signOutLink}?error=${queryParams.error}` : signOutLink;
    setAuthTokens(null);
    setUser(null);

    sessionStorage.removeItem('authTokens');
    window.location.assign(signOutRedirect);
  };
  const logoutUserRef = useRef(logoutUser);
  logoutUserRef.current = logoutUser;
  const inactivityLogout = (): void => { logoutUserRef.current({ error: 'inactivity' }) };

  useEffect(() => {
    if (tokenResponse?.status === 200) {
      const data = tokenResponse.data;
      setAuthTokens(data);
      setUser(jwtDecode(data.access));
      setIsAuthenticated(true);
      sessionStorage.setItem('authTokens', JSON.stringify(data));
    } else {
      sessionStorage.removeItem('authTokens');
      setAuthTokens(null);
      setUser(null);
    }
  }, [tokenResponse?.status, tokenResponse?.data]);

  useEffect(() => {
    if (!authTokens?.refresh || !refreshResponse?.status) return;
    if (refreshResponse?.status === 200) {
      const data = refreshResponse?.data;
      setAuthTokens(data);
      setUser(jwtDecode(data.access));
      sessionStorage.setItem('authTokens', JSON.stringify(data));
    } else {
      logoutUser();
    }
    if (loading) {
      setLoading(false);
    }
  }, [refreshResponse?.status, authTokens?.refresh, loading, refreshResponse?.data]);

  useEffect(() => {
    const fetchToken = async () => {
      await getTokens(tokenFetch);
    };
    fetchToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isAuthenticated) return;
    if (loading) {
      getRefreshToken({ refresh: authTokens?.refresh }, refreshFetch);
    }

    const interval = setInterval(() => {
      if (authTokens) {
        getRefreshToken({ refresh: authTokens?.refresh }, refreshFetch);
      }
    }, NINE_MINUTES);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authTokens, loading, isAuthenticated]);

  useInactivityLogout(isAuthenticated, inactivityLogout, IDLE_TIMEOUT, THROTTLE_DURATION);

  const contextData = {
    authTokens,
    user,
    logoutUser,
  };
  return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>;
};
