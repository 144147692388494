import React from 'react';
import styled from 'styled-components/macro';
import Link, { LinkProps } from '../atoms/Link';
import IconUser from '../icons/IconUser';

const StyledUserButton = styled.div`
  display: flex;
  align-items: center;
  outline: none;
  color: ${({ theme }) => theme.colors.darkBlack};
`;

const StyledIconUser = styled(IconUser)`
  color: ${({ theme }) => theme.colors.greyBlue};
  margin-right: ${({ theme }) => theme.spacing.small}px;
  padding-top: 4px;
`;

const LinkText = styled(Link)`
  line-height: 30px;
  font-weight: bold;
  font-size: 14px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export interface SignInProps {
  link: LinkProps;
}

const SignIn = ({ link }: SignInProps): JSX.Element => {
  return (
    <StyledUserButton>
      <Link href={link.href} title={link.title} target={link.target}>
        <StyledIconUser />
      </Link>
      <LinkText href={link.href} title={link.title} target={link.target}>
        Sign In
      </LinkText>
    </StyledUserButton>
  );
};

export default SignIn;
