import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Button from '../atoms/Button';
import Text from '../atoms/Text';
import IconArrowDown from '../icons/IconArrowDown';
import IconArrowUp from '../icons/IconArrowUp';
import IconUser from '../icons/IconUser';

export type UserMenuOption = {
  className?: string;
  displayText: string;
  onClick: React.MouseEventHandler;
};

export type User = {
  name?: string;
};

const StyledUserMenu = styled.div`
  flex: 0 0 auto;
  max-height: 55px;

  display: flex;
  align-content: flex-end;
  flex-direction: column;
  z-index: 15;
  position: relative;
`;

const StyledUserButton = styled(Button)<{ active: boolean }>`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 12px 24px;

  height: 60px;

  outline: none;
  background-color: ${({ theme, active }) => (active ? theme.colors.darkBlack : theme.colors.white)};
  color: ${({ theme, active }) => (active ? theme.colors.primaryPurple : theme.colors.darkBlack)};

  border-bottom-right-radius: ${({ active, theme }) => (active ? 0 : theme.borders.radius.small)}px;
  border-bottom-left-radius: ${({ active, theme }) => (active ? 0 : theme.borders.radius.small)}px;

  &:focus {
    background-color: ${({ theme, active }) => (active ? theme.colors.darkBlack : theme.colors.white)};
    box-shadow: none;
  }

  &:hover {
    box-shadow: none;
  }
  @media (max-width: 768px) {
    height: 54px;
  }
`;

const StyledIconUser = styled(IconUser)`
  color: ${({ theme }) => theme.colors.greyBlue};
  margin-right: ${({ theme }) => theme.spacing.small}px;
`;

const ButtonText = styled(Text)`
  flex: 1 0 auto;
  line-height: 32px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledIconArrowUp = styled(IconArrowUp)`
  color: ${({ theme }) => theme.colors.primaryPurple};
`;

const UserButton = ({ user, active, onClick }: { user: User; active: boolean; onClick: React.MouseEventHandler }): JSX.Element => {
  return (
    <StyledUserButton active={active} buttonStyle="secondary" onClick={onClick}>
      <StyledIconUser />
      <ButtonText color="primaryPurple" textStyle="buttonText">
        {user.name}
      </ButtonText>
      {active ? <StyledIconArrowUp /> : <IconArrowDown />}
    </StyledUserButton>
  );
};

type StyledMenuItemProps = {
  isHighlighted?: boolean;
};

const StyledMenuItem = styled.div<StyledMenuItemProps>`
  padding: ${({ theme }) => theme.spacing.medium}px;
  border-bottom: solid ${({ theme }) => theme.borders.width.thin}px ${({ theme }) => theme.colors.greyBlue};

  background-color: ${({ theme }) => theme.colors.navy};
  color: ${({ theme }) => theme.colors.almostWhite};
  font-weight: ${({ isHighlighted }) => isHighlighted && 'bold'};

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primaryPurple};
  }

  &:last-child {
    border-bottom-left-radius: ${({ theme, isHighlighted }) => !isHighlighted && theme.borders.radius.small}px;
    border-bottom-right-radius: ${({ theme, isHighlighted }) => !isHighlighted && theme.borders.radius.small}px;
  }
`;

const StyledMenuItemText = styled(Text)`
  color: inherit;
`;

const MenuItem = ({
  text,
  onClick,
  className,
  role,
}: {
  text: string;
  onClick?: React.MouseEventHandler;
  className?: string;
  role?: string;
}): JSX.Element => {
  return (
    <StyledMenuItem onClick={onClick} className={className}>
      <StyledMenuItemText textStyle="smallContent" role={role}>
        {text}
      </StyledMenuItemText>
    </StyledMenuItem>
  );
};

const StyledUser = styled.span`
  display: none;
  @media (max-width: 768px) {
    display: inline-block;
  }
`;

const UserMenuOptions = ({ options, user }: { options: UserMenuOption[]; user: User }) => {
  return (
    <>
      {user?.name && (
        <StyledUser>
          <StyledMenuItem isHighlighted={true}>
            <StyledMenuItemText textStyle="smallContent">{user.name}</StyledMenuItemText>
          </StyledMenuItem>
        </StyledUser>
      )}
      {options.map((option) => (
        <MenuItem key={option.displayText} text={option.displayText} onClick={option.onClick} role="link" />
      ))}
    </>
  );
};

export interface HeaderUserProps {
  user: User;
  options: UserMenuOption[];
  className?: string;
}

const UserMenu = ({ user, options, className }: HeaderUserProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <StyledUserMenu ref={ref} className={className}>
      <UserButton active={open} user={user} onClick={() => setOpen((open) => !open)} />
      {open && <UserMenuOptions options={options} user={user} />}
    </StyledUserMenu>
  );
};

export default UserMenu;
