import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { Colors } from '../../tokens/Colors';

type ColorKey = keyof typeof Colors | 'inherit';

export const textStyles = {
  marketingLargestHeader: css<{ color?: ColorKey }>`
    font-family: ${({ theme }) => theme.fonts.sansBold};
    font-size: 4.8rem;
    line-height: 6.4rem;
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.colors[color || 'darkBlack'])};
  `,
  marketingSmallHeader: css<{ color?: ColorKey }>`
    font-family: ${({ theme }) => theme.fonts.sansBold};
    font-size: 3.2rem;
    line-height: 4.8rem;
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.colors[color || 'darkBlack'])};
  `,
  largestHeader: css<{ color?: ColorKey }>`
    font-family: ${({ theme }) => theme.fonts.sansBold};
    font-size: 6rem;
    line-height: 5rem;
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.colors[color || 'darkBlack'])};
  `,
  largeHeader: css<{ color?: ColorKey }>`
    font-family: ${({ theme }) => theme.fonts.sansBold};
    font-size: 4rem;
    line-height: 5rem;
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.colors[color || 'darkBlack'])};
  `,
  mediumHeader: css<{ color?: ColorKey }>`
    font-family: ${({ theme }) => theme.fonts.sansBold};
    font-size: 3.6rem;
    line-height: 5.4rem;
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.colors[color || 'darkBlack'])};
  `,
  smallerHeader: css<{ color?: ColorKey }>`
    font-family: ${({ theme }) => theme.fonts.sansBold};
    font-size: 2.4rem;
    line-height: 3.2rem;
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.colors[color || 'darkBlack'])};
  `,
  largeTitle: css<{ color?: ColorKey }>`
    font-family: ${({ theme }) => theme.fonts.sansBold};
    font-size: 2.4rem;
    line-height: 3.2rem;
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.colors[color || 'darkBlack'])};
  `,
  largeTitlePublico: css<{ color?: ColorKey }>`
    font-family: ${({ theme }) => theme.fonts.serifBold};
    font-size: 4rem;
    line-height: 6rem;
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.colors[color || 'darkBlack'])};
  `,
  mediumTitle: css<{ color?: ColorKey }>`
    font-family: ${({ theme }) => theme.fonts.sansBold};
    font-size: 2rem;
    line-height: 3rem;
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.colors[color || 'darkBlack'])};
  `,
  mediumTitlePublico: css<{ color?: ColorKey }>`
    font-family: ${({ theme }) => theme.fonts.serifBold};
    font-size: 2rem;
    line-height: 3rem;
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.colors[color || 'darkBlack'])};
  `,
  smallTitle: css<{ color?: ColorKey }>`
    font-family: ${({ theme }) => theme.fonts.sansBold};
    font-size: 1.8rem;
    line-height: 2.7rem;
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.colors[color || 'darkBlack'])};
  `,
  largestContent: css<{ color?: ColorKey }>`
    font-family: ${({ theme }) => theme.fonts.sansRegular};
    font-size: 2rem;
    line-height: 3rem;
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.colors[color || 'darkBlack'])};
  `,
  largeContent: css<{ color?: ColorKey }>`
    font-family: ${({ theme }) => theme.fonts.sansRegular};
    font-size: 1.8rem;
    line-height: 2.7rem;
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.colors[color || 'darkBlack'])};
  `,
  standardContent: css<{ color?: ColorKey }>`
    font-family: ${({ theme }) => theme.fonts.sansRegular};
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.colors[color || 'darkBlack'])};
  `,
  standardContentBold: css<{ color?: ColorKey }>`
    font-family: ${({ theme }) => theme.fonts.sansBold};
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.colors[color || 'darkBlack'])};
  `,
  smallContent: css<{ color?: ColorKey }>`
    font-family: ${({ theme }) => theme.fonts.sansRegular};
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.colors[color || 'darkBlack'])};
  `,
  smallContentBold: css<{ color?: ColorKey }>`
    font-family: ${({ theme }) => theme.fonts.sansBold};
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.colors[color || 'darkBlack'])};
  `,
  smallContentPublico: css<{ color?: ColorKey }>`
    font-family: ${({ theme }) => theme.fonts.serifBold};
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.colors[color || 'darkBlack'])};
  `,
  buttonText: css<{ color?: ColorKey }>`
    font-family: ${({ theme }) => theme.fonts.sansBold};
    font-size: 1.4rem;
    line-height: 1;
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.colors[color || 'darkBlack'])};
  `,
  label: css<{ color?: ColorKey }>`
    font-family: ${({ theme }) => theme.fonts.sansRegular};
    font-size: 1.2rem;
    line-height: 2.1rem;
    text-transform: uppercase;
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.colors[color || 'darkBlack'])};
  `,
  disclaimer: css<{ color?: ColorKey }>`
    font-family: ${({ theme }) => theme.fonts.sansRegular};
    font-size: 1.2rem;
    line-height: 2.1rem;
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.colors[color || 'darkBlack'])};
  `,
  smallLabel: css<{ color?: ColorKey }>`
    font-family: ${({ theme }) => theme.fonts.sansBold};
    font-size: 1rem;
    line-height: 1.8rem;
    text-transform: uppercase;
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.colors[color || 'darkBlack'])};
  `,
  labelBold: css<{ color?: ColorKey }>`
    font-family: ${({ theme }) => theme.fonts.sansBold};
    font-size: 1.2rem;
    line-height: 2.1rem;
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.colors[color || 'darkBlack'])};
  `,
};

export interface TextProps {
  textStyle?: keyof typeof textStyles;
  className?: string;
  tag?: 'div' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'td' | 'th' | 'span' | 'small' | 'em' | 'label' | 'strong' | 'legend';
  color?: keyof typeof Colors | 'inherit';
  truncate?: boolean;
  disabled?: boolean;
  for?: string;
  role?: string;
}

const StyledText = styled.span<TextProps>`
  cursor: inherit;
  ${({ textStyle = 'default' }) => {
    return textStyles[textStyle as keyof typeof textStyles];
  }}
  ${({ truncate = false }) =>
    truncate &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

const Text: React.FunctionComponent<TextProps> = ({ textStyle, tag = 'span', children, color, className, ...rest }) => {
  return (
    <StyledText color={color} textStyle={textStyle} as={tag as any} className={className} tag={tag} {...rest}>
      {children}
    </StyledText>
  );
};

export default Text;
