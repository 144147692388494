import React from 'react';
import { ReactComponent as SvgIconSpinner } from '../../icons/SVG/ICON_SPINNER.svg';
import SvgIcon from './SvgIcon';

export const IconSpinner = ({ className }: { className?: string }): JSX.Element => (
  <SvgIcon className={className}>
    <SvgIconSpinner />
  </SvgIcon>
);

export default IconSpinner;
