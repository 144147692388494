import React from 'react';
import styled from 'styled-components/macro';
import Brand from './Brand';
import Text from './Text';

export type FooterLinkProps = {
  children?: string;
  href?: string;
  className?: string;
  target?: string;
  rel?: string;
};

export interface FooterProps {
  links?: FooterLinkProps[];
  className?: string;
}

const defaultLinks = [
  {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: 'https://www.ama-assn.org/general-information/general-information/privacy-policy',
    children: 'Privacy Policy',
  },
  {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: 'https://www.ama-assn.org/general-information/general-information/accessibility-statement',
    children: 'Accessibility Statement',
  },
  {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: 'https://www.ama-assn.org/general-information/general-information/terms-use',
    children: 'Terms & Conditions',
  },
  {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: 'https://education.ama-assn.org/help/',
    children: 'Contact Us',
  },
];

const FooterLinks = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const FooterLink = styled.a`
  display: inline-block;
  padding: 0 ${({ theme }) => theme.spacing.medium}px;
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  border-right: solid thin ${({ theme }) => theme.colors.white};

  &:hover {
    text-decoration: underline;
  }

  &:last-child {
    border-right: none;
  }

  @media (max-width: 768px) {
    display: block;
    text-align: center;
    border: 0px;
  }
`;

const FooterRule = styled.hr`
  color: ${({ theme }) => theme.colors.greyBlue};
  margin: ${({ theme }) => theme.spacing.jumbo}px;
  width: 90%;
  border-bottom: none;
`;

const Copyright = styled(Text)`
  color: ${({ theme }) => theme.colors.greyBlue};
  margin-bottom: ${({ theme }) => theme.spacing.jumbo}px;
  margin-top: ${({ theme }) => theme.spacing.large}px;
  @media (max-width: 768px) {
    font-size: 10px !important;
    text-align: center;
  }
`;

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.darkBlack};
  padding: ${({ theme }) => theme.spacing.jumbo}px 0;
`;

const Footer = ({ className, links = defaultLinks }: FooterProps): JSX.Element => {
  return (
    <StyledFooter className={className}>
      <FooterLinks>
        {links.map((link) => (
          <FooterLink key={link.children} target={link.target} rel={link.rel} href={link.href}>
            {link.children}
          </FooterLink>
        ))}
      </FooterLinks>
      <FooterRule />
      <Brand width={185} height={35} />
      <Copyright color="greyBlue" textStyle="smallContent">
        {`© ${new Date().getFullYear()} American Medical Association. All Rights Reserved. ®`}
      </Copyright>
    </StyledFooter>
  );
};

export default Footer;
