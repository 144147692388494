import React from 'react';
import { ReactComponent as SvgIconHamburger } from '../../icons/SVG/ICON_HAMBURGER.svg';
import SvgIcon from './SvgIcon';

export const IconHamburger = ({ className }: { className?: string }): JSX.Element => (
  <SvgIcon className={className}>
    <SvgIconHamburger />
  </SvgIcon>
);

export default IconHamburger;
