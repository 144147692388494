import axios from 'axios';
const BASE_URL = process.env.REACT_APP_CURRICULUM_URL;

export default axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Pragma: 'no-cache',
    'Cache-Control': 'no-store, no-cache, must-revalidate',
  },
});
