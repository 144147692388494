import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { theme } from './theme';

export const GlobalStyle = createGlobalStyle`
  ${reset}

  @font-face {
    font-family: 'AkkuratStd-Light';
    font-weight: 300;
    font-style: normal;
    src: url('https://ama-edhub-design-system.s3.amazonaws.com/assets/fonts/AkkuratStd-Light.woff2') format('woff2'), url('https://ama-edhub-design-system.s3.amazonaws.com/assets/fonts/AkkuratStd-Light.woff') format('woff');
  }

  @font-face {
    font-family: 'AkkuratStd-LightItalic';
    font-weight: 300;
    font-style: italic;
    src: url('https://ama-edhub-design-system.s3.amazonaws.com/assets/fonts/AkkuratStd-LightItalic.woff2') format('woff2'), url('https://ama-edhub-design-system.s3.amazonaws.com/assets/fonts/AkkuratStd-LightItalic.woff') format('woff');
  }

  @font-face {
    font-family: 'AkkuratStd';
    font-weight: 400;
    font-style: normal;
    src: url('https://ama-edhub-design-system.s3.amazonaws.com/assets/fonts/AkkuratStd.woff2') format('woff2'), url('https://ama-edhub-design-system.s3.amazonaws.com/assets/fonts/AkkuratStd.woff') format('woff');
  }

  @font-face {
    font-family: 'AkkuratStd-Italic';
    font-weight: 400;
    font-style: italic;
    src: url('https://ama-edhub-design-system.s3.amazonaws.com/assets/fonts/AkkuratStd-Italic.woff2') format('woff2'), url('https://ama-edhub-design-system.s3.amazonaws.com/assets/fonts/AkkuratStd-Italic.woff') format('woff');
  }

  @font-face {
    font-family: 'AkkuratStd-Bold';
    font-weight: 700;
    font-style: normal;
    src: url('https://ama-edhub-design-system.s3.amazonaws.com/assets/fonts/AkkuratStd-Bold.woff2') format('woff2'), url('https://ama-edhub-design-system.s3.amazonaws.com/assets/fonts/AkkuratStd-Bold.woff') format('woff');
  }

  @font-face {
    font-family: 'AkkuratStd-BoldItalic';
    font-weight: 700;
    font-style: italic;
    src: url('https://ama-edhub-design-system.s3.amazonaws.com/assets/fonts/AkkuratStd-BoldItalic.woff2') format('woff2'), url('https://ama-edhub-design-system.s3.amazonaws.com/assets/fonts/AkkuratStd-BoldItalic.woff') format('woff');
  }

  @font-face {
    font-family: 'PublicoText-Bold';
    font-weight: 700;
    font-style: normal;
    src: url('https://ama-edhub-design-system.s3.amazonaws.com/assets/fonts/PublicoText-Bold.woff2') format('woff2'), url('https://ama-edhub-design-system.s3.amazonaws.com/assets/fonts/PublicoText-Bold.woff') format('woff');
  }

  @font-face {
    font-family: 'PublicoText-BoldItatlic';
    font-weight: 700;
    font-style: italic;
    src: url('https://ama-edhub-design-system.s3.amazonaws.com/assets/fonts/PublicoText-BoldItalic.woff2') format('woff2'), url('https://ama-edhub-design-system.s3.amazonaws.com/assets/fonts/PublicoText-BoldItalic.woff') format('woff');
  }

  html {
    /* Make rems easier to calculate. 62.5% of 16px is 10px, so 1rem = 10px */
    font-size: 62.5%;
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    /* Aligns with the Text component "Standard Content" styling */
    font-family: ${({ theme }) => theme.fonts.sansRegular};
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${theme.colors.darkBlack};
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }

  a {
    color: ${({ theme }) => theme.colors.primaryPurple};
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }

  button {
    margin: 0;
  }

  em {
    font-family: ${({ theme }) => theme.fonts.sansRegularItalic};
  }

  strong {
    font-family: ${({ theme }) => theme.fonts.sansBold};
  }

  img,
  svg {
    display: block;
    max-width: 100%;
    height: auto;
  }

  dl,
  dd {
    margin: 0;
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  p {
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-size: 1.6rem;
    font-weight: inherit;
  }

  sup {
    /* 'top' matches designs better than 'super' */
    vertical-align: top;
    font-size: smaller;
  }

  pre {
    padding: 1rem;
    background-color: ${({ theme }) => theme.colors.lightGreyBlue};
    font-family: monospace;
    font-size: 1.4rem;
    line-height: 2.1rem;
  }

  /* TODO: Look into moving this in context of textarea only, if needed */
  textarea {
    /* Aligns with the Text component "Standard Content" styling */
    font-family: ${({ theme }) => theme.fonts.sansRegular};
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${theme.colors.darkBlack};
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
`;

export default GlobalStyle;
