import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import Text from './Text';
import IconSpinner from '../icons/IconSpinner';

interface LoadingProps {
  label?: string;

  className?: string;
}

const StyledLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${({ theme }) => theme.spacing.medium}px;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledSpinnerContainer = styled.div`
  display: inline-block;
  animation: ${spin} 0.8s infinite linear;
  width: 64px;
  height: 64px;
`;

const StyledIconSpinner = styled(IconSpinner)`
  width: 64px;
  height: 64px;
`;

const StyledText = styled(Text)`
  padding: ${({ theme }) => theme.spacing.medium}px;
`;

const Loading = ({ label, className }: LoadingProps): JSX.Element => {
  return (
    <StyledLoadingContainer className={className}>
      <StyledSpinnerContainer>
        <StyledIconSpinner />
      </StyledSpinnerContainer>
      {label && <StyledText textStyle="smallContent">{label}</StyledText>}
    </StyledLoadingContainer>
  );
};

export default Loading;
