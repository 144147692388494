export const Fonts = {
  sansBold: 'AkkuratStd-Bold',
  sansBoldItalic: 'AkkuratStd-BoldItalic',
  sansLight: 'AkkuratStd-Light',
  sansLightItalic: 'AkkuratStd-LightItalic',
  sansRegular: 'AkkuratStd',
  sansRegularItalic: 'AkkuratStd-Italic',
  serifBold: 'PublicoText-Bold',
  serifBoldItalic: 'PublicoText-BoldItalic',
};
