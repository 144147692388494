import { useEffect, useRef } from 'react';
import { SCHEMA_TAG } from '../../constants';

// Why we're attaching to head:
// https://stackoverflow.com/questions/436411/where-should-i-put-script-tags-in-html-markup

type SchemaScriptTagProps = { title: string; url: string };

const SchemaScriptTag: React.FC<SchemaScriptTagProps> = ({ title, url }) => {
  const scriptRef = useRef<HTMLScriptElement>(null);
  const scriptTags = JSON.stringify({ ...SCHEMA_TAG, url, title });

  useEffect(() => {
    if (scriptRef.current) {
      const currentRef = scriptRef.current;
      document.head.appendChild(currentRef);

      return () => {
        if (currentRef) {
          document.head.removeChild(currentRef);
        }
      };
    }
  }, [scriptRef]);

  return (
    <script ref={scriptRef} async>
      {scriptTags}
    </script>
  );
};

export default SchemaScriptTag;
