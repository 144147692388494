import React from 'react';
import styled from 'styled-components/macro';
import { ASSET_URL, DEFAULT_BRAND } from '../../constants';

export interface BrandProps {
  className?: string;
  brand?: string;
  small?: boolean;
  width: number;
  height: number;
}

const Branding = styled.div<BrandProps>`
  background-image: ${({ brand }) => (brand ? `url('${ASSET_URL}/${brand}.svg')` : `url('${ASSET_URL}/${DEFAULT_BRAND}.svg')`)};
  background-size: ${({ small, width, height }) => (small ? `${width * 0.9}px ${height * 0.9}px` : `${width}px ${height}px`)};
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    background-size: ${({ width, height }) => `${width * 0.8}px ${height * 0.8}px`};
    width: ${({ width }) => `${width * 0.8}px`};
    height: ${({ height }) => `${height * 0.8}px`};
  }
`;

export const Brand = ({ className, brand, small, width, height }: BrandProps): JSX.Element => {
  return <Branding brand={brand} className={className} small={small} width={width} height={height} />;
};

export default Brand;
