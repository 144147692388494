import { ReactNode } from 'react';
import ReactDom from 'react-dom';
import styled from 'styled-components/macro';
import { Loading, Modal } from '../../design_system/src/web/atoms';

const OverlayStyles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 20;
`;

const StyledModal = styled(Modal)`
  z-index: 21;
`;

type ModalWrapperProps = {
  onClose?: React.ChangeEventHandler;
  children: ReactNode;
  displayLoading?: boolean;
  isActive: boolean;
  hideClose?: boolean;
};

const ModalWrapper: React.FC<ModalWrapperProps> = ({ onClose, children, isActive, displayLoading = false, hideClose = false }) => {
  return ReactDom.createPortal(
    <>
      <OverlayStyles />
      <StyledModal onClose={onClose} isActive={isActive} hideClose={hideClose}>
        {children}
        {displayLoading && <Loading />}
      </StyledModal>
    </>,
    // eslint-disable-next-line
    document.getElementById('portal')!,
  );
};

export default ModalWrapper;
