import React from 'react';
import styled from 'styled-components/macro';
import IconCloseRounded from '../icons/IconCloseRounded';

export interface ModalProps {
  children: any;
  className?: string;
  hideClose?: boolean;
  isActive: boolean;
  onClose?: any;
}

const StyledModal = styled.div`
  background-color: #fff;
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borders.radius.tiny}px;
  border: 1px solid ${({ theme }) => theme.colors.lightBlue};
  left: 50%;
  max-width: 500px;
  min-height: 230px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -90%);
  width: 100%;
  z-index: 10;
`;

const StyledCloseIcon = styled(IconCloseRounded)`
  cursor: pointer;
  float: right;
  margin: ${({ theme }) => theme.spacing.small}px;
`;

const StyledChildren = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge}px;
`;

export const Modal = ({ className, children, isActive, onClose, hideClose }: ModalProps): JSX.Element => {
  return (
    <div>
      {isActive && (
        <>
          <StyledModal className={className}>
            {!hideClose && (
              <div onClick={() => onClose(!isActive)}>
                <StyledCloseIcon />
              </div>
            )}
            <StyledChildren>{children}</StyledChildren>
          </StyledModal>
        </>
      )}
    </div>
  );
};

export default Modal;
