import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import IconCloseRounded from '../icons/IconCloseRounded';
import Text from './Text';

interface AlertProps {
  $type?: keyof typeof alertStyles;
  className?: string;
  text?: string;
  onClick?: () => void;
}

export const alertStyles = {
  info: css`
    border-top: 1px solid ${({ theme }) => theme.colors.lightBlue};
    border-right: 1px solid ${({ theme }) => theme.colors.lightBlue};
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBlue};
    border-left: ${({ theme }) => theme.spacing.tiny}px solid ${({ theme }) => theme.colors.lightBlue};
  `,
  warning: css`
    border-top: 1px solid ${({ theme }) => theme.colors.amaYellow};
    border-right: 1px solid ${({ theme }) => theme.colors.amaYellow};
    border-bottom: 1px solid ${({ theme }) => theme.colors.amaYellow};
    border-left: ${({ theme }) => theme.spacing.tiny}px solid ${({ theme }) => theme.colors.amaYellow};
  `,
  error: css`
    border-top: 1px solid ${({ theme }) => theme.colors.amaRed};
    border-right: 1px solid ${({ theme }) => theme.colors.amaRed};
    border-bottom: 1px solid ${({ theme }) => theme.colors.amaRed};
    color: ${({ theme }) => theme.colors.amaRed};
    border-left: ${({ theme }) => theme.spacing.tiny}px solid ${({ theme }) => theme.colors.amaRed};
  `,
  success: css`
    border-top: 1px solid ${({ theme }) => theme.colors.amaGreen};
    border-right: 1px solid ${({ theme }) => theme.colors.amaGreen};
    border-bottom: 1px solid ${({ theme }) => theme.colors.amaGreen};
    border-left: ${({ theme }) => theme.spacing.tiny}px solid ${({ theme }) => theme.colors.amaGreen};
  `,
};

const StyledAlert = styled.div<AlertProps>`
  ${({ $type = 'info' }) => {
    return alertStyles[$type as keyof typeof alertStyles];
  }}
  line-height: 1.6rem;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.small}px ${({ theme }) => theme.spacing.medium}px;
  background: ${({ theme }) => theme.colors.white};
  text-align: left;
  margin-bottom: ${({ theme }) => theme.spacing.medium}px;
  border-radius: ${({ theme }) => theme.borders.radius.tiny}px;
  white-space: pre-wrap;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const StyledIcon = styled(IconCloseRounded)`
  color: ${({ theme }) => theme.colors.darkBlack};
`;

const StyledText = styled(Text)`
  color: inherit;
`;

export const Alert: React.FunctionComponent<AlertProps> = ({ $type, className, text, ...rest }) => {
  const [isActive, setIsActive] = useState<boolean>(true);
  return (
    <>
      {isActive && (
        <StyledAlert $type={$type} className={className} {...rest}>
          <StyledText tag="p" textStyle="standardContent">
            {text}
          </StyledText>

          <div onClick={() => setIsActive((prevIsActive) => !prevIsActive)}>
            <StyledIcon />
          </div>
        </StyledAlert>
      )}
    </>
  );
};
