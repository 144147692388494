import React from 'react';
import styled from 'styled-components/macro';
import HamburgerMenu from '../molecules/HamburgerMenu';
import Brand from './Brand';
import { LinkProps } from './Link';
export interface HeaderProps {
  className?: string;
  links?: LinkProps[];
}

type ConfigProps = {
  sticky?: boolean;
};

export const StyledHeader = styled.header<ConfigProps>`
  background: ${({ theme }) => theme.colors.darkBlack};
  display: flex;
  height: 65px;
  width: 100%;
  z-index: 100;
  @media (max-width: 768px) {
    height: 55px;
  }
`;

const StyledBrand = styled.div<ConfigProps>`
  align-self: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledHamburgerMenu = styled.div<ConfigProps>`
  width: 36px;
  padding: 16px;
  margin-right: auto;
`;

export const Header = ({ links, className }: HeaderProps): JSX.Element => {
  return (
    <StyledHeader className={className}>
      {links && links.length > 0 && (
        <StyledHamburgerMenu>
          <HamburgerMenu links={links} />
        </StyledHamburgerMenu>
      )}
      <StyledBrand>
        <Brand brand="edhub-darkBg" width={185} height={35} />
      </StyledBrand>
    </StyledHeader>
  );
};

export default Header;
