import React from 'react';
import { ReactComponent as SvgIconCheckboxRounded } from '../../icons/SVG/ICON_CHECKBOX_ROUNDED.svg';
import SvgIcon from './SvgIcon';

export const IconCheckboxRounded = ({ className }: { className?: string }): JSX.Element => (
  <SvgIcon className={className}>
    <SvgIconCheckboxRounded />
  </SvgIcon>
);

export default IconCheckboxRounded;
