import React from 'react';
import styled from 'styled-components/macro';
import { LinkProps } from '../atoms/Link';

export interface CenteredNavigationProps {
  links: LinkProps[];
  className?: string;
  sticky?: boolean;
}

type StyledNavProps = {
  sticky?: boolean;
};

const StyledCenteredNavigation = styled.nav<StyledNavProps>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.navy};
  height: 67px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: ${({ sticky }) => sticky && 'fixed'};
  top: ${({ sticky }) => sticky && '65px'};
  left: ${({ sticky }) => sticky && '0px'};
`;

const NavLink = styled.a`
  display: inline-block;
  padding: 0 ${({ theme }) => theme.spacing.medium}px;
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }

  &:last-child {
    border-right: none;
  }

  @media (max-width: 768px) {
    display: block;
    text-align: center;
    border: 0px;
  }
`;

export const CenteredNavigation = ({ className, links, sticky }: CenteredNavigationProps): JSX.Element => {
  return (
    <StyledCenteredNavigation className={className} sticky={sticky}>
      {links.map((link) => (
        <NavLink key={link.title} target={link.target} rel={link.rel} href={link.href}>
          {link.children}
        </NavLink>
      ))}
    </StyledCenteredNavigation>
  );
};

export default CenteredNavigation;
