import React, { ChangeEventHandler, useRef } from 'react';
import styled, { css } from 'styled-components/macro';
import Text from '../atoms/Text';
import IconCheckboxRounded from '../icons/IconCheckboxRounded';

export interface CheckboxProps {
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  type?: 'radio' | 'checkbox';
  label?: string;
  disabled?: boolean;
  className?: string;
}

const Checkbox: React.FunctionComponent<CheckboxProps> = ({
  checked,
  onChange,
  type = 'checkbox',
  label = '',
  disabled = false,
  className = '',
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleClick = (event: React.MouseEvent) => {
    if (inputRef.current) {
      inputRef.current.click();
    }
    event.preventDefault();
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.checked !== checked) {
      onChange(event);
    }
  };

  return (
    <CheckboxContainer className={className}>
      <StyleCheckboxLabel onClick={handleClick} aria-checked={checked} tabIndex={checked ? 0 : -1}>
        <StyledCheckbox checked={checked} disabled={disabled}>
          <input ref={inputRef} type={type} checked={checked} onChange={disabled ? undefined : handleChange} />
          {checked && <CheckmarkIcon />}
        </StyledCheckbox>
        {label && (
          <Text textStyle="standardContent" color={disabled ? 'greyBlue' : 'darkBlack'}>
            {label}
          </Text>
        )}
      </StyleCheckboxLabel>
    </CheckboxContainer>
  );
};

export const CheckboxContainer = styled.div``;

const StyleCheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.navy};
  outline: none;
  user-select: none;
`;

const checkboxDimension = 18;

const StyledCheckbox = styled.div<{ checked: boolean; disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${checkboxDimension}px;
  height: ${checkboxDimension}px;
  background-color: ${({ theme, checked }) => (checked ? theme.colors.navy : theme.colors.white)};
  color: ${({ theme }) => theme.colors.darkBlack};
  padding: 0;
  margin-right: 10px;
  border: 1px solid ${({ theme, checked }) => (checked ? theme.colors.navy : theme.colors.greyBlue)};
  border-radius: 3px;
  outline: none;

  & > input {
    display: none;
  }

  ${({ theme, disabled, checked }) =>
    disabled &&
    css`
      border-color: ${theme.colors.greyBlue};

      ${checked
        ? css`
            background-color: ${theme.colors.greyBlue};
          `
        : css`
            background-color: ${theme.colors.almostWhite};
          `}

      ${CheckmarkIcon} {
        color: ${theme.colors.lightGrey};
      }
    `}
`;

const CheckmarkIcon = styled(IconCheckboxRounded)`
  width: ${checkboxDimension}px;
  height: ${checkboxDimension}px;
  color: ${({ theme }) => theme.colors.white};
`;

export default Checkbox;
