import React from 'react';
import styled from 'styled-components/macro';

export interface TextareaProps {
  name: string;
  value?: string;
  hasError?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

const StyledTextarea = styled.textarea`
  appearance: none;
  min-height: ${({ theme }) => theme.textareas.height};
  font-size: ${({ theme }) => theme.inputs.fontSize};
  font-family: ${({ theme }) => theme.fonts.sansRegular};
  width: 100%;
  border: 0;
  padding: ${({ theme }) => theme.spacing.medium}px;
  border-radius: ${({ theme }) => theme.borders.radius.tiny}px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkBlack};
  border: 1px solid ${({ theme, hasError }: any) => (hasError ? theme.colors.amaRed : theme.colors.greyBlue)};
  resize: none;
  display: block;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.almostWhite};
    border: 1px solid ${({ theme }) => theme.colors.lightBlue};
    color: ${({ theme }) => theme.colors.greyBlue};
  }
`;

const Textarea = ({ name, ...rest }: TextareaProps) => {
  return <StyledTextarea name={name} {...rest} />;
};

export default Textarea;
