import { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FlashContext } from '../context/FlashProvider';
import { PageProps } from '../context/PageProvider';
import { Text } from '../design_system/src/web/atoms';
import { WelcomePageProps } from '../types';
import WelcomeBanner from './WelcomeBanner';
import BannerWrapper from './shared/BannerWrapper';
import HelpWrapper from './shared/HelpWrapper';

const WelcomePage: React.FC<WelcomePageProps> = ({ page }) => {
  const [searchParams] = useSearchParams();
  const isError = searchParams.get('error');
  const { flashDispatch } = useContext(FlashContext);

  const { programTitle, bannerImg, phoneNumber, email, signInLink, newInstitutionLink, errorMessages }: PageProps = page;

  useEffect(() => {
    if (isError && errorMessages && errorMessages[isError]) {
      flashDispatch({ message: errorMessages[isError], type: 'error' });
    }
  }, [isError, flashDispatch, errorMessages]);

  if (!bannerImg || !phoneNumber || !email || !signInLink || !newInstitutionLink || !programTitle) return null;

  return (
    <>
      <BannerWrapper bannerImg={bannerImg}>
        <WelcomeBanner programTitle={programTitle} signInLink={signInLink} />
      </BannerWrapper>
      <HelpWrapper>
        <Text tag="p" textStyle="standardContent" color="navy">
          I have recently transferred to a <a href={newInstitutionLink}>new institution</a>.
        </Text>
        <Text tag="p" textStyle="standardContent" color="navy">
          Give us a call at {phoneNumber} or email <a href={`mailto:${email}`}>{email}</a>.
        </Text>
      </HelpWrapper>
    </>
  );
};

export default WelcomePage;
