import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import {
  ENTER_CODE_LABEL,
  ENTER_CODE_PLACEHOLDER,
  PENDING_SUBMIT_MODAL_TEXT,
  SUBMIT
} from '../constants';
import { errorMessages } from '../mappings';

import { submitSubmissionCode } from '../api/requests';
import useAxios from '../api/useAxios';
import { FlashContext } from '../context/FlashProvider';
import { Input, Text } from '../design_system/src/web/atoms';
import { StyledButton } from './shared/BannerWrapper';
import Modal from './shared/ModalWrapper';

/** Although the codes currently are 6 characters, advantage is able to accept up to 10 characters.
 * See https://www.pivotaltracker.com/story/show/185131365/comments/236777931
 */
const MAX_CODE_LENGTH = 10;

const StyledInput = styled(Input)`
  margin: ${({ theme }) => theme.spacing.medium}px 0px;
  width: 500px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledModalText = styled(Text)`
  text-align: center;
`;

const AccessSubmitCode: React.FC<{ productCode: string, signInLink: string }> = ({ signInLink, productCode }) => {
  const [subscriptionCode, setSubscriptionCode] = useState<string>('');
  const [subscriptionCodeSubmitted, setSubscriptionCodeSubmitted] = useState<boolean>(false);
  const { response, loading, axiosFetch } = useAxios();

  const { flashDispatch } = useContext(FlashContext);

  useEffect(() => {
    if (response?.status === 204) {
      window.open(signInLink, '_self');
    } else if (response?.status === 500) {
      flashDispatch({ message: errorMessages[productCode].server, type: 'error' });
    } else if (response?.status === 404) {
      flashDispatch({ message: errorMessages[productCode].invalidCode, type: 'error' });
    }
  }, [response?.status, flashDispatch, signInLink, productCode]);

  const resetSubscriptionCode = () => {
    setSubscriptionCodeSubmitted(false);
    setSubscriptionCode('');
  };

  const handleSubscriptionCodeSubmit = async (event: any) => {
    const formattedSubscriptionCode = subscriptionCode.trim();

    // Show error message if subscription code is not alphanumerical (leading and trailing spaces are allowed)
    event.preventDefault();
    if (!/^[a-z0-9]+$/i.test(formattedSubscriptionCode)) {
      flashDispatch({ message: errorMessages[productCode].notAlphanumeric, type: 'error' });
      setSubscriptionCode(formattedSubscriptionCode);
    } else {
      flashDispatch({ type: 'reset' });
      resetSubscriptionCode();
      await submitSubmissionCode({ subscriptionCode: formattedSubscriptionCode }, axiosFetch);
    }
  };

  const handleSubscriptionCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubscriptionCode(event.target.value);
  };

  return (
    <>
      <form onSubmit={handleSubscriptionCodeSubmit}>
        <StyledInput
          name={ENTER_CODE_LABEL}
          placeholder={ENTER_CODE_PLACEHOLDER}
          aria-label={ENTER_CODE_LABEL}
          value={subscriptionCode}
          maxLength={MAX_CODE_LENGTH}
          onChange={handleSubscriptionCodeChange}
        />
        <StyledButton
          buttonStyle="secondary"
          type="submit"
          testId="submitCode"
          disabled={subscriptionCode.trim().length === 0 || subscriptionCodeSubmitted}
        >
          {SUBMIT}
        </StyledButton>
      </form>

      {loading && (
        <Modal displayLoading={true} isActive={loading} hideClose={true}>
          <StyledModalText tag="p">{PENDING_SUBMIT_MODAL_TEXT}</StyledModalText>
        </Modal>
      )}
    </>
  );
};

export default AccessSubmitCode;
