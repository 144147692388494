import React from 'react';
import styled from 'styled-components/macro';
import HamburgerMenu from '../molecules/HamburgerMenu';
import SignIn, { SignInProps } from '../molecules/SignIn';
import UserMenu from '../molecules/UserMenu';
import Brand from './Brand';
import { LinkProps } from './Link';
import { HeaderUserProps } from './ScrollingHeader';

export interface SubHeaderProps {
  className?: string;
  subLogo: string;
  sticky?: boolean;
  signIn: SignInProps;
  links?: LinkProps[];
  subLogoWidth: number;
  subLogoHeight: number;
  hidePrimarySubLogo?: boolean;
  hideSignIn?: boolean;
  userMenu: HeaderUserProps;
}

type ConfigProps = {
  sticky?: boolean;
};

export const StyledHeader = styled.header<ConfigProps>`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: row;
  height: 65px;
  left: ${({ sticky }) => sticky && '0'};
  position: ${({ sticky }) => sticky && 'fixed'};
  top: ${({ sticky }) => sticky && '0'};
  width: 100%;
  z-index: 100;
  @media (max-width: 768px) {
    height: 55px;
  }
`;

const StyledHamburgerMenu = styled.div<ConfigProps>`
  width: 36px;
  flex: 36px 0 0;
  padding: 16px;
`;

const StyledBrand = styled.span`
  margin-left: 72px;
  align-self: center;
  @media (max-width: 768px) {
    margin-left: 17px;
  }
`;

const StyledSignIn = styled.span`
  margin-left: auto;
  margin-right: 72px;
  align-self: center;
  @media (max-width: 768px) {
    margin-right: 12px;
  }
`;

export const SubHeader = ({
  subLogo,
  className,
  sticky,
  signIn,
  links,
  subLogoWidth,
  subLogoHeight,
  userMenu,
  hideSignIn,
  hidePrimarySubLogo,
}: SubHeaderProps): JSX.Element => {
  const { user, userMenuLink } = userMenu;

  return (
    <StyledHeader className={className} sticky={sticky}>
      {links && (
        <StyledHamburgerMenu>
          <HamburgerMenu links={links} />
        </StyledHamburgerMenu>
      )}
      <StyledBrand>{!hidePrimarySubLogo && <Brand brand={subLogo} width={subLogoWidth} height={subLogoHeight} />}</StyledBrand>
      {!hideSignIn && (
        <StyledSignIn>{user?.name ? <UserMenu user={user} options={[userMenuLink]} /> : <SignIn {...signIn} />}</StyledSignIn>
      )}
    </StyledHeader>
  );
};

export default SubHeader;
