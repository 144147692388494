import 'styled-components';
import { DefaultTheme } from 'styled-components';
import { Borders } from '../../tokens/Borders';
import { Colors } from '../../tokens/Colors';
import { Fonts } from '../../tokens/Fonts';
import { Shadows } from '../../tokens/Shadows';
import { Spacing } from '../../tokens/Spacing';

export const common = {
  input: {
    height: '44px',
    fontSize: '1.6rem',
  },
  textarea: {
    height: '100px',
  },
};

// and extend them!
declare module 'styled-components' {
  export interface DefaultTheme {
    name: string;
    borders: typeof Borders;
    fonts: typeof Fonts;
    spacing: typeof Spacing;
    colors: typeof Colors;
    shadows: typeof Shadows;
    inputs: typeof common.input;
    textareas: typeof common.textarea;
  }
}

export const theme: DefaultTheme = {
  name: 'AMA',
  borders: Borders,
  colors: Colors,
  fonts: Fonts,
  spacing: Spacing,
  shadows: Shadows,
  inputs: common.input,
  textareas: common.textarea,
};

export const accessibleTheme: DefaultTheme = {
  name: 'AMA (Accessible)',
  borders: Borders,
  colors: Colors,
  fonts: Fonts,
  spacing: Spacing,
  shadows: Shadows,
  inputs: common.input,
  textareas: common.textarea,
};
