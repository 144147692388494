import React from 'react';
import styled from 'styled-components/macro';

export interface TempBannerProps {
  children?: any;
  className?: string;
}

const StyledTempBanner = styled.div`
  background: ${({ theme }) => theme.colors.greyBlue};
  color: white;
  font-size: 20px;
  height: 462px;
  padding-top: 90px;
  width: 100%;
`;

export const TempBanner = ({ className, children }: TempBannerProps): JSX.Element => {
  return <StyledTempBanner className={className}>{children}</StyledTempBanner>;
};

export default TempBanner;
