import React from 'react';
import styled from 'styled-components/macro';
import { EMAIL_ADDRESS_LABEL, SUBMIT } from '../constants';
import { Button, Input, Text } from '../design_system/src/web/atoms';
import Field from '../design_system/src/web/molecules/Field';

const StyledText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

const StyledPrimaryButton = styled(Button)`
  margin: 0 auto;
`;

type AccessEmailFormProps = {
  handleSubmitEmail: React.FormEventHandler<HTMLFormElement> | undefined;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  error: string | null;
  submitted: boolean;
  gradType: string;
};

const AccessEmailForm: React.FC<AccessEmailFormProps> = ({ handleSubmitEmail, email, setEmail, error, submitted }) => {
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <>
      <StyledText tag="p">Enter the email submitted by your institution, and we'll send your code momentarily.</StyledText>
      <form onSubmit={handleSubmitEmail}>
        <Field errorText={error}>
          <Input
            name={EMAIL_ADDRESS_LABEL}
            placeholder={'Email Address'}
            aria-label={EMAIL_ADDRESS_LABEL}
            value={email}
            onChange={handleEmailChange}
            hasError={!!error}
          />
        </Field>
        <StyledPrimaryButton buttonStyle="primary" type="submit" testId="submitEmail" disabled={submitted || email.trim().length === 0}>
          {SUBMIT}
        </StyledPrimaryButton>
      </form>
    </>
  );
};

export default AccessEmailForm;
