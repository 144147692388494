import {
  ABOUT_AMA,
  ABOUT_AMA_LINK,
  EDHUB_DESCRIPTION,
  EDHUB_LOGO_LIGHT_BACKGROUND,
  GCEP_ACCESS_DESCRIPTION,
  GCEP_BANNER,
  GCEP_DESCRIPTION,
  GCEP_ED_TYPE,
  GCEP_HELP_EMAIL,
  GCEP_LEARN_MORE_LINK,
  GCEP_PRODUCT_CODE,
  GCEP_PROGRAM_NAME,
  GME_LOGO_LIGHT_BACKGROUND,
  HELP_PHONE_NUMBER,
  HOME,
  HOME_LINK,
  HSEP_ACCESS_DESCRIPTION,
  HSEP_BANNER,
  HSEP_DESCRIPTION,
  HSEP_ED_TYPE,
  HSEP_HELP_EMAIL,
  HSEP_LOGO_LIGHT_BACKGROUND,
  HSEP_PRODUCT_CODE,
  HSEP_PROGRAM_NAME,
  LEARN_MORE,
  MEMBER_BENEFITS,
  MEMBER_BENEFITS_LINK,
  META_URL,
  SIGN_IN,
  UCEP_ACCESS_DESCRIPTION,
  UCEP_BANNER,
  UCEP_DESCRIPTION,
  UCEP_ED_TYPE,
  UCEP_HELP_EMAIL,
  UCEP_LEARN_MORE_LINK,
  UCEP_PRODUCT_CODE,
  UCEP_PROGRAM_NAME,
  UME_LOGO_LIGHT_BACKGROUND
} from '../constants';

import { newInstitutionLink, productsLink, signInLink } from '../links';
import { errorMessages } from '../mappings';

export const gcepPagesData = [
  {
    bannerImg: GCEP_BANNER,
    gradType: GCEP_ED_TYPE,
    email: GCEP_HELP_EMAIL,
    hidePrimarySubLogo: false,
    metaDescription: GCEP_DESCRIPTION,
    metaTitle: 'GCEP',
    metaUrl: `${META_URL}/gcep`,
    pageType: 'welcome',
    phoneNumber: HELP_PHONE_NUMBER,
    productCode: GCEP_PRODUCT_CODE,
    programTitle: GCEP_PROGRAM_NAME,
    protected: false,
    routes: ['/gcep/', '/v2/gcep/'],
    signInLink: `${signInLink}?productCode=gcep`,
    newInstitutionLink: `${newInstitutionLink}?productCode=gcep`,
    subLogo: GME_LOGO_LIGHT_BACKGROUND,
    subLogoHeight: 35,
    subLogoWidth: 200,
    errorMessages: errorMessages['gcep'],
    links: [
      {
        target: '_self',
        href: `${signInLink}?productCode=gcep`,
        title: SIGN_IN,
        children: SIGN_IN,
      },
      {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: MEMBER_BENEFITS_LINK,
        title: MEMBER_BENEFITS,
        children: MEMBER_BENEFITS,
      },
      {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: GCEP_LEARN_MORE_LINK,
        title: LEARN_MORE,
        children: LEARN_MORE,
      },
    ],
  },
  {
    bannerImg: GCEP_BANNER,
    gradType: GCEP_ED_TYPE,
    email: GCEP_HELP_EMAIL,
    hidePrimarySubLogo: false,
    metaDescription: GCEP_ACCESS_DESCRIPTION,
    metaTitle: 'Subscription Access',
    metaUrl: `${META_URL}/gcep/access`,
    pageType: 'access',
    phoneNumber: HELP_PHONE_NUMBER,
    productCode: GCEP_PRODUCT_CODE,
    programTitle: GCEP_PROGRAM_NAME,
    protected: true,
    routes: ['gcep/access/', 'v2/gcep/access/'],
    signInLink: `${signInLink}?productCode=gcep`,
    subLogo: GME_LOGO_LIGHT_BACKGROUND,
    subLogoHeight: 35,
    subLogoWidth: 200,
    links: [
      {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: MEMBER_BENEFITS_LINK,
        title: MEMBER_BENEFITS,
        children: MEMBER_BENEFITS,
      },
      {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: GCEP_LEARN_MORE_LINK,
        title: LEARN_MORE,
        children: LEARN_MORE,
      },
    ],
  },
  {
    hidePrimarySubLogo: true,
    metaDescription: EDHUB_DESCRIPTION,
    metaTitle: 'Edhub Subscription',
    metaUrl: `${META_URL}`,
    pageType: 'notFound',
    protected: false,
    routes: ['*', 'v2/*'],
    signInLink: `${signInLink}?productCode=gcep`,
    subLogo: EDHUB_LOGO_LIGHT_BACKGROUND,
    subLogoHeight: 35,
    subLogoWidth: 200,
    links: [
      {
        rel: 'noopener noreferrer',
        target: '_blank',
        href: HOME_LINK,
        title: HOME,
        children: HOME,
      },
      {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: MEMBER_BENEFITS_LINK,
        title: MEMBER_BENEFITS,
        children: MEMBER_BENEFITS,
      },
      {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: ABOUT_AMA_LINK,
        title: ABOUT_AMA,
        children: ABOUT_AMA,
      },
    ],
  },
];

export const ucepPagesData = [
  {
    bannerImg: UCEP_BANNER,
    gradType: UCEP_ED_TYPE,
    email: UCEP_HELP_EMAIL,
    hidePrimarySubLogo: false,
    metaDescription: UCEP_DESCRIPTION,
    metaTitle: 'UCEP',
    metaUrl: `${META_URL}/ucep`,
    pageType: 'welcome',
    phoneNumber: HELP_PHONE_NUMBER,
    productCode: UCEP_PRODUCT_CODE,
    programTitle: UCEP_PROGRAM_NAME,
    protected: false,
    routes: ['/ucep/', '/v2/ucep/'],
    signInLink: `${signInLink}?productCode=ucep`,
    newInstitutionLink: `${newInstitutionLink}?productCode=ucep`,
    subLogo: UME_LOGO_LIGHT_BACKGROUND,
    subLogoHeight: 70,
    subLogoWidth: 263,
    errorMessages: errorMessages['ucep'],
    links: [
      {
        target: '_self',
        href: `${signInLink}?productCode=ucep`,
        title: SIGN_IN,
        children: SIGN_IN,
      },
      {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: MEMBER_BENEFITS_LINK,
        title: MEMBER_BENEFITS,
        children: MEMBER_BENEFITS,
      },
      {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: UCEP_LEARN_MORE_LINK,
        title: LEARN_MORE,
        children: LEARN_MORE,
      },
    ],
  },
  {
    bannerImg: UCEP_BANNER,
    gradType: UCEP_ED_TYPE,
    email: UCEP_HELP_EMAIL,
    hidePrimarySubLogo: false,
    metaDescription: UCEP_ACCESS_DESCRIPTION,
    metaTitle: 'Subscription Access',
    metaUrl: `${META_URL}/ucep/access`,
    pageType: 'access',
    phoneNumber: HELP_PHONE_NUMBER,
    productCode: UCEP_PRODUCT_CODE,
    programTitle: UCEP_PROGRAM_NAME,
    protected: true,
    routes: ['ucep/access/', 'v2/ucep/access/'],
    signInLink: `${signInLink}?productCode=ucep`,
    subLogo: UME_LOGO_LIGHT_BACKGROUND,
    subLogoHeight: 70,
    subLogoWidth: 263,
    links: [
      {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: MEMBER_BENEFITS_LINK,
        title: MEMBER_BENEFITS,
        children: MEMBER_BENEFITS,
      },
      {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: UCEP_LEARN_MORE_LINK,
        title: LEARN_MORE,
        children: LEARN_MORE,
      },
    ],
  },
  {
    hidePrimarySubLogo: true,
    metaDescription: EDHUB_DESCRIPTION,
    metaTitle: 'Edhub Subscription',
    metaUrl: `${META_URL}`,
    pageType: 'notFound',
    protected: false,
    routes: ['*', 'v2/*'],
    signInLink: `${signInLink}?productCode=ucep`,
    subLogo: EDHUB_LOGO_LIGHT_BACKGROUND,
    subLogoHeight: 70,
    subLogoWidth: 263,
    links: [
      {
        rel: 'noopener noreferrer',
        target: '_blank',
        href: HOME_LINK,
        title: HOME,
        children: HOME,
      },
      {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: MEMBER_BENEFITS_LINK,
        title: MEMBER_BENEFITS,
        children: MEMBER_BENEFITS,
      },
      {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: ABOUT_AMA_LINK,
        title: ABOUT_AMA,
        children: ABOUT_AMA,
      },
    ],
  },
];

export const hsepPagesData = [
  {
    bannerImg: HSEP_BANNER,
    gradType: HSEP_ED_TYPE,
    email: HSEP_HELP_EMAIL,
    hidePrimarySubLogo: false,
    metaDescription: HSEP_DESCRIPTION,
    metaTitle: 'Opioids Substance Use Curriculum for Physicians',
    metaUrl: `${META_URL}/mate-act`,
    pageType: 'welcome',
    phoneNumber: HELP_PHONE_NUMBER,
    productCode: HSEP_PRODUCT_CODE,
    programTitle: HSEP_PROGRAM_NAME,
    protected: false,
    routes: ['/mate-act/', '/v2/mate-act/'],
    signInLink: `${signInLink}?productCode=mate-act`,
    newInstitutionLink: `${newInstitutionLink}?productCode=mate-act`,
    subLogo: HSEP_LOGO_LIGHT_BACKGROUND,
    subLogoHeight: 70,
    subLogoWidth: 263,
    errorMessages: errorMessages['mate-act'],
    links: [
      {
        target: '_self',
        href: `${signInLink}?productCode=mate-act`,
        title: SIGN_IN,
        children: SIGN_IN,
      },
    ],
  },
  {
    bannerImg: HSEP_BANNER,
    gradType: HSEP_ED_TYPE,
    email: HSEP_HELP_EMAIL,
    hidePrimarySubLogo: false,
    metaDescription: HSEP_ACCESS_DESCRIPTION,
    metaTitle: 'Subscription Access - Opioids Substance Use Curriculum for Physicians',
    metaUrl: `${META_URL}/mate-act/access`,
    pageType: 'access',
    phoneNumber: HELP_PHONE_NUMBER,
    productCode: HSEP_PRODUCT_CODE,
    programTitle: HSEP_PROGRAM_NAME,
    protected: true,
    routes: ['mate-act/access/', 'v2/mate-act/access/'],
    signInLink: `${signInLink}?productCode=mate-act`,
    subLogo: HSEP_LOGO_LIGHT_BACKGROUND,
    subLogoHeight: 70,
    subLogoWidth: 263,
    links: [],
  },
  {
    hidePrimarySubLogo: true,
    metaDescription: EDHUB_DESCRIPTION,
    metaTitle: 'Opioids Substance Use Curriculum for Physicians',
    metaUrl: `${META_URL}`,
    pageType: 'notFound',
    protected: false,
    routes: ['*', 'v2/*'],
    signInLink: `${signInLink}?productCode=mate-act`,
    subLogo: EDHUB_LOGO_LIGHT_BACKGROUND,
    subLogoHeight: 70,
    subLogoWidth: 263,
    links: [
      {
        rel: 'noopener noreferrer',
        target: '_blank',
        href: HOME_LINK,
        title: HOME,
        children: HOME,
      },
    ],
  },
];

export const genericPagesData = [
  {
    hidePrimarySubLogo: true,
    metaDescription: EDHUB_DESCRIPTION,
    metaTitle: 'Edhub Subscription',
    metaUrl: `${META_URL}`,
    pageType: 'root',
    protected: false,
    routes: ['/', '/v2'],
    subLogo: EDHUB_LOGO_LIGHT_BACKGROUND,
    subLogoHeight: 35,
    subLogoWidth: 200,
    links: [
      {
        rel: 'noopener noreferrer',
        target: '_blank',
        href: HOME_LINK,
        title: HOME,
        children: HOME,
      },
      {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: MEMBER_BENEFITS_LINK,
        title: MEMBER_BENEFITS,
        children: MEMBER_BENEFITS,
      },
      {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: ABOUT_AMA_LINK,
        title: ABOUT_AMA,
        children: ABOUT_AMA,
      },
    ],
  },
  {
    hidePrimarySubLogo: true,
    metaDescription: EDHUB_DESCRIPTION,
    metaTitle: 'Edhub Subscription',
    metaUrl: `${META_URL}`,
    pageType: 'notFound',
    protected: false,
    routes: ['*', 'v2/*'],
    signInLink: `${productsLink}`,
    subLogo: EDHUB_LOGO_LIGHT_BACKGROUND,
    subLogoHeight: 35,
    subLogoWidth: 200,
    links: [
      {
        rel: 'noopener noreferrer',
        target: '_blank',
        href: HOME_LINK,
        title: HOME,
        children: HOME,
      },
      {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: MEMBER_BENEFITS_LINK,
        title: MEMBER_BENEFITS,
        children: MEMBER_BENEFITS,
      },
      {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: ABOUT_AMA_LINK,
        title: ABOUT_AMA,
        children: ABOUT_AMA,
      },
    ],
  },
];
