type SpacingKeys = 'micro' | 'tiny' | 'small' | 'medium' | 'large' | 'xlarge' | 'jumbo';

export const Spacing = {
  micro: 2,
  tiny: 4,
  small: 8,
  medium: 16,
  large: 24,
  xlarge: 32,
  jumbo: 48,
  px: (value: SpacingKeys): string => `${Spacing[value]}px`,
};
