import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../tokens/Colors';

interface Props {
  children?: any;
  bordered?: boolean;
  bgColor?: keyof typeof Colors;
  className?: string;
}

const StyledContentContainer = styled.div<Props>`
  background-color: ${({ theme, bgColor }) => theme.colors[bgColor ?? 'white']};
  padding: 0 ${({ theme }) => theme.spacing.jumbo}px;
  border-bottom: ${({ theme, bordered }) => `solid ${theme.borders.width.thin}px ${bordered ? theme.colors.lightGreyBlue : 'transparent'}`};
`;

export const ContentContainer = ({ children, bordered, bgColor, className }: Props): JSX.Element => (
  <StyledContentContainer bordered={bordered} bgColor={bgColor} className={className}>
    {children}
  </StyledContentContainer>
);

export default ContentContainer;
