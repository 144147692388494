import React from 'react';
import styled from 'styled-components/macro';
import { theme as amaTheme } from '../themes/theme';

export interface Option<T = string | number | boolean> {
  value: T;
  label: string;
}

export interface SelectProps {
  name: string;
  options: Option[];
  readonly?: boolean;
  onChange?: React.ChangeEventHandler;
  value?: string;
  displayEmpty?: string;
  className?: string;
  defaultValue?: string;
  hasError?: boolean;
}

export const Select = ({ options, name, readonly, defaultValue, displayEmpty, value, hasError, ...rest }: SelectProps): JSX.Element => {
  return (
    <SelectWrap hasError={hasError}>
      <StyledSelect disabled={readonly} as="select" name={name} value={value} defaultValue={defaultValue} hasError={hasError} {...rest}>
        {displayEmpty && <option value="">{displayEmpty}</option>}
        {options.map((option: Option) => (
          <option key={option.value.toString()} value={option.value.toString()} data-test-id={`select-value-${option.value}`}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
    </SelectWrap>
  );
};

const determineSelectValueColor = ({
  theme = amaTheme,
  value,
  hasError,
}: {
  theme: typeof amaTheme;
  value?: string;
  hasError?: boolean;
}) => {
  if (hasError) return theme.colors.amaRed;
  if (value) return theme.colors.darkBlack;
  return theme.colors.darkGrey;
};

const StyledSelect = styled.select<{ value?: string; hasError?: boolean }>`
  appearance: none;
  height: ${({ theme }) => theme.inputs.height};
  font-size: ${({ theme }) => theme.inputs.fontSize};
  font-family: ${({ theme }) => theme.fonts.sansRegular};
  width: 100%;
  border: 0;
  padding: 0 ${({ theme }) => theme.spacing.large}px 0 ${({ theme }) => theme.spacing.medium}px;
  border-radius: ${({ theme }) => theme.borders.radius.tiny}px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme, value, hasError }) => determineSelectValueColor({ theme, value, hasError })};
  border: 1px solid ${({ theme, hasError }) => (hasError ? theme.colors.amaRed : theme.colors.greyBlue)};

  &:disabled {
    background-color: ${({ theme }) => theme.colors.almostWhite};
    border: 1px solid ${({ theme }) => theme.colors.lightBlue};
    color: ${({ theme }) => theme.colors.greyBlue};
  }
`;

const SelectWrap = styled.div<{ hasError?: boolean }>`
  position: relative;

  &::after {
    content: '▾';
    position: absolute;
    color: ${({ theme, hasError }) => (hasError ? theme.colors.amaRed : theme.colors.darkBlack)};
    right: ${({ theme }) => theme.spacing.medium}px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

export default Select;
