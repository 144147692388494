export const initialState = { message: '', type: '' };

export interface FlashAction {
  type: 'success' | 'error' | 'reset';
  message: string;
}

export const flashReducer = (state = initialState, action: FlashAction) => {
  switch (action.type) {
    case 'success':
      return { ...state, type: action.type, message: action.message };
    case 'error':
      return { ...state, type: action.type, message: action.message };
    case 'reset':
      return initialState;
    default:
      throw new Error(`This action type isn't supported.`);
  }
};
