import { useContext } from 'react';

import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import './App.css';
import AccessPage from './components/AccessPage';
import NotFound from './components/NotFound';
import RootPage from './components/RootPage';
import WelcomePage from './components/WelcomePage';
import { PageContext, PageProps } from './context/PageProvider';
import { GlobalStyle, theme } from './design_system/web';
import Layout from './layouts/Layout';

type DynamicComponentProps = ({ page }: { page: PageProps }) => JSX.Element | null;

function App() {
  const { location } = window;
  const pages = useContext(PageContext);

  // Prevent v2 routes being shown on the original verify site
  if (location.pathname.includes('v2') && location.hostname.includes('verify')) {
    location.pathname = '/';
  }

  // Prevent v2 routes being shown in staging and prod
  if (!location.hostname.includes('localhost') && location.pathname.includes('v2') && location.hostname.includes('curriculum')) {
    location.pathname = location.pathname.replace('/v2', '');
  }

  const pageTemplate: Record<string, DynamicComponentProps> = {
    welcome: WelcomePage,
    access: AccessPage,
    root: RootPage,
    notFound: NotFound,
  };

  if (!pages) return null;

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        {pages.map((page: PageProps) =>
          page.routes.map((route: string) => {
            const Template: DynamicComponentProps = pageTemplate[page.pageType];
            return (
              <Route element={<Layout page={page} />} key={route}>
                <Route path={route} element={<Template page={page} />} />
              </Route>
            );
          }),
        )}
      </Routes>
      <GlobalStyle />
    </ThemeProvider>
  );
}

export default App;
