import React from 'react';
import styled from 'styled-components/macro';
import Text from '../atoms/Text';

export interface FieldProps {
  children: React.ReactElement<any>;
  label?: string;
  disabled?: boolean;
  errorText?: string | null;
  className?: string;
}

const StyledField = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

const StyledFieldLabel = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing.small}px;
  display: block;
`;

const StyledFieldElement = styled.div``;

const StyledFieldError = styled(Text)`
  padding-top: ${({ theme }) => theme.spacing.small}px;
  color: ${({ theme }) => theme.colors.amaRed};
`;

const Field = ({ label, children, errorText, className }: FieldProps): JSX.Element => {
  return (
    <StyledField className={className}>
      {label && (
        <StyledFieldLabel tag="label" textStyle="standardContent" color={errorText ? 'amaRed' : 'navy'}>
          {label}
        </StyledFieldLabel>
      )}
      <StyledFieldElement>{children}</StyledFieldElement>
      {errorText && (
        <StyledFieldError tag="p" textStyle="smallContent">
          {errorText}
        </StyledFieldError>
      )}
    </StyledField>
  );
};

export default Field;
