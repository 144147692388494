import styled from 'styled-components/macro';
import { ACTIVATE_ACCOUNT, ENTER_SECURITY_CODE } from '../constants';

import { Text } from '../design_system/src/web/atoms';
import AccessResetCode from './AccessRequestCode';
import AccessSubmitCode from './AccessSubmitCode';

const StyledText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

type AccessFormProps = {
  signInLink: string;
  gradType: string;
  productCode: string;
};

const StyledParagraph = styled(Text)`
  margin: 0px;
`;

const AccessBanner: React.FC<AccessFormProps> = ({ signInLink, gradType, productCode }) => (
  <>
    <StyledText tag="h1" textStyle="largeHeader" color="white">
      {ACTIVATE_ACCOUNT}
    </StyledText>
    <StyledParagraph tag="p" textStyle="standardContent" color="white">
      {ENTER_SECURITY_CODE}
    </StyledParagraph>
    <AccessResetCode gradType={gradType} productCode={productCode} />
    <AccessSubmitCode signInLink={signInLink} productCode={productCode} />
  </>
);

export default AccessBanner;
