import { useContext } from 'react';
import { ACCESS_CURRICULA, SIGN_IN } from '../constants';
import { AuthContext } from '../context/AuthProvider';
import { Text } from '../design_system/src/web/atoms';
import { StyledButton } from './shared/BannerWrapper';

const RootBanner: React.FC<{ name: string; signInLink: string }> = ({ name, signInLink }) => {
  const { user } = useContext(AuthContext);
  const buttonText = user?.name ? 'Access' : SIGN_IN;

  return (
    <>
      <Text tag="h1" textStyle="largeHeader" color="white">
        {name}
      </Text>
      <Text tag="p" textStyle="largeContent" color="white">
        {ACCESS_CURRICULA}
      </Text>

      <StyledButton buttonStyle="secondary" href={signInLink} target="_self">
        {buttonText}
      </StyledButton>
    </>
  );
};

export default RootBanner;
