import { throttle } from 'lodash';
import { useEffect } from 'react';

const useInactivityLogout = (isAuthenticated: boolean, runOnTimeout: () => void, IDLE_TIMEOUT: number, THROTTLE_DURATION: number) => {
  useEffect(() => {
    if (!isAuthenticated) return;
    let inactivityTimeout: any;

    function callback() {
      runOnTimeout();
    }

    const inactivityTimer = () => {
      clearTimeout(inactivityTimeout);
      inactivityTimeout = setTimeout(callback, IDLE_TIMEOUT);
    };

    const throttledInactivityTimer = throttle(inactivityTimer, THROTTLE_DURATION);
    inactivityTimer(); // Start the inactivity timeout as soon as the hook is invoked
    document.addEventListener('mousemove', throttledInactivityTimer);
    document.addEventListener('mousedown', throttledInactivityTimer);
    document.addEventListener('keypress', throttledInactivityTimer);
    document.addEventListener('touchmove', throttledInactivityTimer);
    document.addEventListener('scroll', throttledInactivityTimer);

    return () => {
      clearTimeout(inactivityTimeout);
      document.removeEventListener('mousemove', throttledInactivityTimer);
      document.removeEventListener('mousedown', throttledInactivityTimer);
      document.removeEventListener('keypress', throttledInactivityTimer);
      document.removeEventListener('touchmove', throttledInactivityTimer);
      document.removeEventListener('scroll', throttledInactivityTimer);
    };
  }, [isAuthenticated, runOnTimeout, IDLE_TIMEOUT, THROTTLE_DURATION]);
}

export default useInactivityLogout;
