import { useContext } from 'react';
import styled from 'styled-components/macro';
import { ProductContext, ProductProps } from '../context/ProductProvider';
import { Text } from '../design_system/src/web/atoms';
import RootBanner from './RootBanner';
import BannerWrapper from './shared/BannerWrapper';
import HelpWrapper from './shared/HelpWrapper';

const StyledText = styled(Text)`
  margin: ${({ theme }) => theme.spacing.small}px 0px;
`;

const Product = styled.div`
  margin-bottom: 5px;
`;

// TODO: fix type
const RootPage: React.FC<any> = () => {
  const products: ProductProps[] | undefined = useContext(ProductContext);

  if (!products) return null;

  return (
    <>
      {products.map(({ bannerImg, name, signInLink }: { bannerImg: string; name: string; signInLink: string }) => {
        return (
          <Product key={name}>
            <BannerWrapper bannerImg={bannerImg} key={name}>
              <RootBanner name={name} signInLink={signInLink} />
            </BannerWrapper>
          </Product>
        );
      })}
      <HelpWrapper>
        {products.map(({ name, email, phoneNumber }: { name: string; email: string; phoneNumber: string }) => {
          return (
            <div key={name}>
              <StyledText tag="p" textStyle="standardContent" color="navy">
                <strong>{name}: </strong>If you need help, contact us at <a href={`mailto:${email}`}>{email}</a> or give us a call at {phoneNumber}
              </StyledText>
            </div>
          );
        })}
      </HelpWrapper>
    </>
  );
};

export default RootPage;
